import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Util } from '../util/util';
import { environment } from 'src/environments/environment';
import { AccessorService } from './accessor.service';
import { TsLogger } from '../log/log';

interface initializeInfo {
  tenantName: string;
}

@Injectable({
  providedIn: 'root',
})
export class InitializeService {
  //初期処理が終わった時にこの値をtrueに変更する
  initializeCompleteFlg: boolean = false;
  //子テナント自身の名前格納
  tenantName: string = '';

  constructor(private accessorService: AccessorService) {}

  //トークン取得用のURL設定を行う
  getInitializeInfoObservable = (): Observable<initializeInfo> => {
    // とりあえず同じURLを呼ぶものだけでも共通化する。
    let response: Observable<initializeInfo>;
    if (Util.getDevMode()) {
      const url = environment.getXsrfToken;
      response = this.accessorService.get(url);
    } else {
      const url = Util.getInitializeUrl();
      response = this.accessorService.get(url);
    }
    return response;
  };

  setTenantName = () => {
    return new Promise((resolve) => {
      let response = this.getInitializeInfoObservable();
      response.subscribe(
        (data) => {
          this.tenantName = data.tenantName;
          resolve('success');
        },
        (error) => {
          TsLogger().log(error);
          resolve('error');
        }
      );
    });
  };

  get getInitializeCompleteFlg(): boolean {
    return this.initializeCompleteFlg;
  }
  set setInitializeCompleteFlg(flg: boolean) {
    this.initializeCompleteFlg = flg;
  }

  get getTenantName(): string {
    return this.tenantName;
  }
}
