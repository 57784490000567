<div class="sidebar">
    <div class="hamberger" (click)="onHambergerMenu(true)"></div>
    <ul class="sideList" *ngIf="!firstTime">
        <li *ngIf="wordInfoService.getWordInfo()" class="sideList_item-log" (click)="onMain()"
            [ngClass]=" nowPrint ? 'now' : ''"></li>
        <li *ngIf="wordInfoService.getWordInfo()" class="sideList_item-menuSettings" (click)="onSetting()"
            [ngClass]=" nowSetting ? 'now' : ''"></li>
    </ul>
</div>
<div class="sideMenu" *ngIf="sideMenuService.getHambergerFlag" [@sideMenu]="sideMenuService.getHambergerFlag">
    <div class="hamberger" (click)="onHambergerMenu(false)"></div>
    <ul class="sideList" *ngIf="!firstTime">
        <!-- <li *ngIf="wordInfoService.getWordInfo()" class="sideList_item-menuPrint" (click)="onMain()"
            [ngClass]=" nowPrint ? 'now' : ''">{{wordInfoService.getWord('main')}}</li>
        <li *ngIf="wordInfoService.getWordInfo()" class="sideList_item-menuJoblist" (click)="onHistory()"
            [ngClass]=" nowJobhistory ? 'now' : ''">{{wordInfoService.getWord('openJobList')}}</li>
        <li *ngIf="wordInfoService.getWordInfo()" class="sideList_item-menuSettings" (click)="onSetting()"
            [ngClass]=" nowSetting ? 'now' : ''">{{wordInfoService.getWord('setting')}}</li> -->
        <li *ngIf="wordInfoService.getWordInfo()" class="sideList_item-log" (click)="onMain()"
            [ngClass]=" nowPrint ? 'now' : ''" [innerHTML]="wordInfoService.getWord('main')"></li>
        <li *ngIf="wordInfoService.getWordInfo()" class="sideList_item-menuSettings" (click)="onSetting()"
            [ngClass]=" nowSetting ? 'now' : ''" [innerHTML]="wordInfoService.getWord('setting')"></li>
    </ul>
    <button class="sideList_logout" (click)="onLogout()">{{wordInfoService.getWord('signout')}}</button>
</div>
<div class="sideDisable" *ngIf="sideMenuService.getHambergerFlag" [@sideDisable]="sideMenuService.getHambergerFlag"
    (click)="onHambergerMenu(false)">
</div>