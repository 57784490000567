import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as Cons from '../../appcommon/constants/constants';
import { Injectable } from '@angular/core';
import { DialogService } from './dialog.service';
import { LoadingService } from './loading.service';
import { TsLogger } from '../log/log';
import { WordInfoService } from './word-info.service';
import { Util } from '../util/util';
import { AccessorService } from './accessor.service';
import { AdminCommonService } from './admin-common.service';
@Injectable({
  providedIn: 'root',
})
export class VendorService {
  //子テナント登録用の子テナント名
  private vendorRegisterName: string = '';
  //子テナント削除の子テナント名
  private vendorDeleteName: string = '';
  //子テナント管理者登録用の子テナント名
  private vendorAdminRegisterName: string = '';
  //子テナント管理者削除用の子テナント名
  private vendorAdminDeleteName: string = '';
  //子テナント管理者検索用の子テナント名
  private vendorAdminSearchName: string = '';
  //子テナント管理者登録用ファイル格納
  private vendorAdminRegistCSVFile: Array<string> = new Array();
  //子テナント管理者登録用ファイル名
  private vendorAdminRegistCSVFileName: string = '';
  //子テナント管理者削除用ファイル格納
  private vendorAdminDeleteCSVFile: Array<string> = new Array();
  //子テナント管理者削除用ファイル名
  private vendorAdminDeleteCSVFileName: string = '';
  //IE用のインターバル止める用ためのID
  private timerId: number = 0;
  //Adminデータ追加用インターバルを止めるためのID
  private postAdminTimerId: number = 0;
  //Adminデータ追加時の分割個数
  private postAdminFileSplitNum: number = 1;
  //Adminの登録成功回数
  private adminRegisterSuccessNum: number = 0;
  //Adminデータ追加が全て処理が完了したかを制御するための変数
  private postAdminCompFlg: Array<boolean> = new Array();
  //delete処理繰返し制御用変数
  private deletePollingNum: number = 0;
  //Adminデータ追加用インターバルを止めるためのID
  private deleteAdminTimerId: number = 0;
  //Adminデータ追加時の分割個数
  private deleteAdminFileSplitNum: number = 1;
  //Adminの登録成功回数
  private adminDeleteSuccessNum: number = 0;
  //Adminデータ追加が全て処理が完了したかを制御するための変数
  private deleteAdminCompFlg: Array<boolean> = new Array();
  //Admin追加時に追加出来なかったデータを格納する。
  private errorListMap: Map<string, Array<string>> = new Map();

  constructor(
    private accessorService: AccessorService,
    private adminCommonService: AdminCommonService,
    public loadingService: LoadingService,
    public wordInfoService: WordInfoService,
    public dialogService: DialogService
  ) {}

  /** 子テナント削除処理 **/
  deleteVendorInfoObservable = (): Observable<{ success: boolean }> => {
    // とりあえず同じURLを呼ぶものだけでも共通化する。
    let response: Observable<{ success: boolean }>;
    if (Util.getDevMode()) {
      const url = environment.deleteSession;
      response = this.accessorService.get(url);
      TsLogger().log(
        this.adminCommonService.getVendorIdMap.get(this.vendorDeleteName)
      );
    } else {
      response = this.accessorService.delete(
        Util.deleteClientInfoUrl(
          this.adminCommonService.getVendorIdMap.get(this.vendorDeleteName)
        )
      );
    }
    return response;
  };

  /** 子テナント登録処理 **/
  postVendorInfoObservable = (): Observable<{ success: boolean }> => {
    // とりあえず同じURLを呼ぶものだけでも共通化する。
    let response: Observable<{ success: boolean }>;
    if (Util.getDevMode()) {
      const url = environment.deleteSession;
      response = this.accessorService.get(url);
    } else {
      response = this.accessorService.post(
        Util.postTenantRequestUrl(),
        JSON.stringify({
          clientName: this.vendorRegisterName,
        })
      );
    }
    return response;
  };

  /** 子テナントのクライアント取得処理 **/
  getClientInfoObservable = (
    vendorName: string
  ): Observable<{
    success: boolean;
    clientInfo: { clientId: string; clientName: string }[];
  }> => {
    // とりあえず同じURLを呼ぶものだけでも共通化する。
    let response: Observable<{
      success: boolean;
      clientInfo: { clientId: string; clientName: string }[];
    }>;
    if (Util.getDevMode()) {
      const url = environment.deleteSession;
      response = this.accessorService.get(url);
    } else {
      response = this.accessorService.get(
        Util.getTenantClientInfoUrl(
          this.adminCommonService.getVendorIdMap.get(vendorName)
        )
      );
    }
    return response;
  };

  //管理者情報を登録する。
  postVendorAdminInfoObservable = (
    mailAddress: string
  ): Observable<{ success: boolean; error: string }> => {
    // とりあえず同じURLを呼ぶものだけでも共通化する。
    let response: Observable<{ success: boolean; error: string }>;
    if (Util.getDevMode()) {
      TsLogger().log(mailAddress);
      let random = Math.ceil(Math.random() * 100);
      if (random <= 20) {
        response = this.accessorService.get(environment.getPostUserResults);
      } else if (random > 20 && random <= 40) {
        response = this.accessorService.get(environment.noClientId);
      } else if (random > 40 && random <= 60) {
        response = this.accessorService.get(environment.existUser);
      } else {
        response = this.accessorService.get(environment.serverError);
      }
    } else {
      response = this.accessorService.post(
        Util.TenantAdminInfoUrl(
          this.adminCommonService.getVendorIdMap.get(
            this.vendorAdminRegisterName
          )
        ),
        JSON.stringify({
          mailAddress: mailAddress,
          groupList: [
            '00000000-0000-0000-0000-000000000002',
            '00000000-0000-0000-0000-000000000003',
          ],
        })
      );
    }
    return response;
  };

  //管理者情報を取得する。
  getVendorAdminInfoService = (
    name: string
  ): Observable<{ success: boolean; adminInfo: Array<string> }> => {
    let response: Observable<{ success: boolean; adminInfo: Array<string> }>;

    if (Util.getDevMode()) {
      response = this.accessorService.get(environment.getAdminInfo);
    } else {
      TsLogger().log('adminInfo取得');
      //アップロードするuserInfoを取得する
      response = this.accessorService.get(
        Util.TenantAdminInfoUrl(
          this.adminCommonService.getVendorIdMap.get(name)
        )
      );
    }
    return response;
  };

  //adminのメールアドレスを取得する
  getAdminMailAdress = (
    hashedMailAdress: string,
    clientId: string | undefined
  ): Observable<{
    success: boolean;
    mailAddress: string;
    groupList: Array<string>;
  }> => {
    let response: Observable<{
      success: boolean;
      mailAddress: string;
      groupList: Array<string>;
    }>;
    if (Util.getDevMode()) {
      TsLogger().log(hashedMailAdress);
      response = this.accessorService.get(environment.getAdminMailAdress);
    } else {
      TsLogger().log('tenantAdminMailAdress取得');
      //アップロードするuserInfoを取得する
      response = this.accessorService.get(
        Util.tenantAdminMailAdressRequestUrl(clientId, hashedMailAdress)
      );
    }
    return response;
  };

  //新規登録・削除用のCSVファイルを配列に格納する
  setAdminPostDeleteCsvArray = (item: string, funcName: string) => {
    let fileArray = item.split('\n');
    let csvFileItem = fileArray.filter((v: string) => v);
    let arrayTmp = new Array();
    //CSVの途中で空が入っている。
    //その空文字は何故か1文字の情報を持った空文字になるためそれを弾く
    for (let i = 0; i < csvFileItem.length; i++) {
      if (csvFileItem[i].length !== 1) {
        arrayTmp.push(
          csvFileItem[i].replace('\r', '').replace(/[\s]/g, '').trim()
        );
      }
    }
    csvFileItem = arrayTmp;
    //csvが1000件より多い場合エラー
    if (csvFileItem.length > Cons.FILELISTLIMIT) {
      this.dialogService.setDialogInfo = {
        body: 'errorFileLimit',
        footer: false,
        next: '',
      };
      //ダイアログの閉じるクリック後の挙動をLambda式で
      this.dialogService.setCallbackDialog = () => {
        //はいが押下されたとき
        this.loadingService.setLoading = false;
        this.clearCsvFileItem(funcName);
      };
      return;
    }
    //csvが一件もない場合はエラーとする。
    if (csvFileItem.length === 0) {
      //errorFileEmpty
      this.dialogService.setDialogInfo = {
        body: 'errorFileEmpty',
        footer: false,
        next: '',
      };
      //ダイアログの閉じるクリック後の挙動をLambda式で
      this.dialogService.setCallbackDialog = () => {
        //はいが押下されたとき
        this.loadingService.setLoading = false;
        this.clearCsvFileItem(funcName);
      };
      return;
    }
    //メールアドレスチェックをする。
    let errorMailAdress = this.adminCommonService.checkMailAdress(csvFileItem);
    if (errorMailAdress !== '') {
      let msg = this.wordInfoService
        .getWord('errorMailAdress')
        .replace('{0}', errorMailAdress);
      this.dialogService.setDialogInfo = {
        body: msg,
        footer: false,
        next: '',
        replaceFlg: true,
      };
      //ダイアログの閉じるクリック後の挙動をLambda式で
      this.dialogService.setCallbackDialog = () => {
        //はいが押下されたとき
        this.loadingService.setLoading = false;
        this.clearCsvFileItem(funcName);
      };
    } else {
      this.loadingService.setLoading = false;
      this.setCsvFileItem(csvFileItem, funcName);
    }
  };

  setCsvFileItem = (csvItem: Array<string>, funcName: string) => {
    if (funcName === Cons.POST) {
      this.vendorAdminRegistCSVFile = csvItem;
    } else {
      this.vendorAdminDeleteCSVFile = csvItem;
    }
  };

  clearCsvFileItem = (funcName: string) => {
    if (funcName === Cons.POST) {
      this.vendorAdminRegistCSVFile = new Array();
      this.vendorAdminRegistCSVFileName = '';
    } else {
      this.vendorAdminDeleteCSVFile = new Array();
      this.vendorAdminDeleteCSVFileName = '';
    }
  };

  //追加時のエラーリストを初期化する。
  postAdminInitmapList = () => {
    for (let i = 0; i < Cons.POSTUSERERRORMAP.length; i++) {
      this.initErrorMap(Cons.POSTUSERERRORMAP[i]);
    }
  };

  //CSVファイル処理ユーザの追加・削除のエラーを格納するためのMAP初期化
  initErrorMap = (mapName: string) => {
    this.errorListMap.set(mapName, new Array<string>());
  };

  //メールアドレスをエラーリストに追加する。
  addMailAdressToErrorMap = (
    errorName: string,
    mailAdress: string,
    list: Array<string>
  ) => {
    let errorNameList = list;
    for (let i = 0; i < errorNameList.length; i++) {
      if (errorNameList[i] === errorName) {
        if (this.errorListMap.get(errorName)) {
          let errormailAdress: Array<string> = this.errorListMap.get(
            errorName
          ) as Array<string>;
          errormailAdress.push(mailAdress);
          this.errorListMap.set(errorName, errormailAdress);
        }
      }
    }
  };

  //追加・削除処理の完了フラグが全てtrueかを確認する
  checkCompFlg = (list: Array<boolean>): boolean => {
    for (let i = 0; i < list.length; i++) {
      if (!list[i]) {
        return false;
      }
    }
    return true;
  };

  //マップの存在チェック
  //リストに値が入っているかを確認する
  checkMapExistMailAdressList = (list: Array<string>): boolean => {
    let errorNameList = list;
    for (let i = 0; i < errorNameList.length; i++) {
      if (
        this.errorListMap.get(errorNameList[i]) &&
        (this.errorListMap.get(errorNameList[i]) as Array<string>).length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  //追加処理が完了した場合に値を格納するための関数
  changePostAdminCompFlg = (result: boolean) => {
    this.postAdminCompFlg.push(result);
  };

  //エラー時のメッセージを生成して返却する。
  createErrorMessage = (
    errorNameList: Array<string>,
    errorNameToMessage: Map<string, string>,
    msg: string
  ): string => {
    let errorMailAdressNum = 0;
    let serverErrorMessage = '';
    for (let i = 0; i < errorNameList.length; i++) {
      if (this.errorListMap.get(errorNameList[i])) {
        let mailAdressList: Array<string> = this.errorListMap.get(
          errorNameList[i]
        ) as Array<string>;
        //サーバーエラー以外は件数を表示する
        if (errorNameList[i] !== 'serverError') {
          errorMailAdressNum += mailAdressList.length;
        } else {
          let messageAdress = '';
          for (let j = 0; j < mailAdressList.length; j++) {
            messageAdress += mailAdressList[j] + '<br>';
          }
          if (
            mailAdressList.length > 0 &&
            errorNameToMessage.get(errorNameList[i])
          ) {
            let nameList: string = errorNameToMessage.get(
              errorNameList[i]
            ) as string;
            serverErrorMessage += this.wordInfoService
              .getWord(nameList)
              .replace('{0}', messageAdress);
          }
        }
      }
    }
    if (errorMailAdressNum !== 0) {
      msg += this.wordInfoService
        .getWord('errorPostDeleteNum')
        .replace('{0}', errorMailAdressNum);
    }
    if (serverErrorMessage !== '') {
      msg += serverErrorMessage;
    }
    return msg;
  };

  //ユーザ削除時のエラーリストを初期化する。
  deleteAdminInitmapList = () => {
    for (let i = 0; i < Cons.DELETEUSERMAP.length; i++) {
      this.initErrorMap(Cons.DELETEUSERMAP[i]);
    }
  };

  getDeleteAdminInfoUrl = (
    hash: string
  ): Observable<{ success: boolean; error: string }> => {
    let response: Observable<{ success: boolean; error: string }>;
    if (Util.getDevMode()) {
      let random = Math.ceil(Math.random() * 100);
      if (random <= 20) {
        response = this.accessorService.get(environment.getDeleteUserResults);
      } else if (random > 20 && random <= 40) {
        response = this.accessorService.get(environment.notExistUser);
      } else if (random > 40 && random <= 60) {
        response = this.accessorService.get(environment.otherClient);
      } else {
        response = this.accessorService.get(environment.serverError);
      }
    } else {
      response = this.accessorService.delete(
        Util.tenantAdminMailAdressRequestUrl(
          this.adminCommonService.getVendorIdMap.get(
            this.vendorAdminDeleteName
          ),
          hash
        )
      );
    }
    return response;
  };

  //管理者削除時に削除成功した場合adminDeleteSuccessNumを+1する
  countUpAdminDeleteSuccessNum = (): void => {
    this.adminDeleteSuccessNum += 1;
  };

  //管理者削除時に処理が成功したか否かのフラグdeleteAdminCompFlgを更新する
  updateDeleteAdminCompFlg = (flg: boolean): void => {
    this.deleteAdminCompFlg.push(flg);
  };

  get getVendorRegisterName(): string {
    return this.vendorRegisterName;
  }
  set setVendorRegisterName(clientName: string) {
    this.vendorRegisterName = clientName;
  }
  get getVendorDeleteName(): string {
    return this.vendorDeleteName;
  }
  set setVendorDeleteName(clientName: string) {
    this.vendorDeleteName = clientName;
  }
  get getVendorAdminRegisterName(): string {
    return this.vendorAdminRegisterName;
  }
  set setVendorAdminRegisterName(name: string) {
    this.vendorAdminRegisterName = name;
  }
  get getVendorAdminDeleteName() {
    return this.vendorAdminDeleteName;
  }
  set setVendorAdminDeleteName(name: string) {
    this.vendorAdminDeleteName = name;
  }
  get getVendorAdminSearchName() {
    return this.vendorAdminSearchName;
  }
  set setVendorAdminSearchName(name: string) {
    this.vendorAdminSearchName = name;
  }
  get getVendorAdminRegistCSVFile() {
    return this.vendorAdminRegistCSVFile;
  }
  set setVendorAdminRegistCSVFile(file: Array<string>) {
    this.vendorAdminRegistCSVFile = file;
  }
  get getVendorAdminRegistCSVFileName() {
    return this.vendorAdminRegistCSVFileName;
  }
  set setVendorAdminRegistCSVFileName(name: string) {
    this.vendorAdminRegistCSVFileName = name;
  }
  get getVendorAdminDeleteCSVFile() {
    return this.vendorAdminDeleteCSVFile;
  }
  set setVendorAdminDeleteCSVFile(file: Array<string>) {
    this.vendorAdminDeleteCSVFile = file;
  }
  get getVendorAdminDeleteCSVFileName() {
    return this.vendorAdminDeleteCSVFileName;
  }
  set setVendorAdminDeleteCSVFileName(name: string) {
    this.vendorAdminDeleteCSVFileName = name;
  }
  get getTimerId(): number {
    return this.timerId;
  }
  set setTimerId(num: number) {
    this.timerId = num;
  }
  get getPostAdminTimerId() {
    return this.postAdminTimerId;
  }
  set setPostAdminTimerId(num: number) {
    this.postAdminTimerId = num;
  }
  get getPostAdminFileSplitNum() {
    return this.postAdminFileSplitNum;
  }
  set setPostAdminFileSplitNum(num: number) {
    this.postAdminFileSplitNum = num;
  }
  get getErrorListMap(): Map<string, Array<string>> {
    return this.errorListMap;
  }
  set setErrorListMap(map: Map<string, Array<string>>) {
    this.errorListMap = map;
  }
  get getPostAdminCompFlg(): Array<boolean> {
    return this.postAdminCompFlg;
  }
  set setPostAdminCompFlg(list: Array<boolean>) {
    this.postAdminCompFlg = list;
  }
  get getAdminRegisterSuccessNum() {
    return this.adminRegisterSuccessNum;
  }
  set setAdminRegisterSuccessNum(num: number) {
    this.adminRegisterSuccessNum = num;
  }
  get getDeleteAdminTimerId() {
    return this.deleteAdminTimerId;
  }
  set setDeleteAdminTimerId(num: number) {
    this.deleteAdminTimerId = num;
  }
  get getDeleteAdminFileSplitNum() {
    return this.deleteAdminFileSplitNum;
  }
  set setDeleteAdminFileSplitNum(num: number) {
    this.deleteAdminFileSplitNum = num;
  }
  get getDeleteAdminCompFlg(): Array<boolean> {
    return this.deleteAdminCompFlg;
  }
  set setDeleteAdminCompFlg(list: Array<boolean>) {
    this.deleteAdminCompFlg = list;
  }
  get getAdminDeleteSuccessNum() {
    return this.adminDeleteSuccessNum;
  }
  set setAdminDeleteSuccessNum(num: number) {
    this.adminDeleteSuccessNum = num;
  }
  get getDeletePollingNum() {
    return this.deletePollingNum;
  }
  set setDeletePollingNum(num: number) {
    this.deletePollingNum = num;
  }
}
