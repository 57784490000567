import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';

import { slideInAnimation } from './animation';
import * as Cons from './appcommon/constants/constants';
import { TsLogger } from './appcommon/log/log';
import { DialogInfo } from './appcommon/model/dialog-info';
import { TabModel } from './appcommon/model/tab-model';
import { DialogService } from './appcommon/service/dialog.service';
import { ErrorService } from './appcommon/service/error.service';
import { InitializeService } from './appcommon/service/initialize.service';
import { LoadingService } from './appcommon/service/loading.service';
import { TabService } from './appcommon/service/tab.service';
import { WordInfoService } from './appcommon/service/word-info.service';
import { TabAddComponent } from './container/tab-add/tab-add.component';
import { TabDeleteComponent } from './container/tab-delete/tab-delete.component';
import { DialogComponent } from './container/dialog/dialog.component';
import { LogDownloadComponent } from './container/log-download/log-download.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // animation
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit {
  @ViewChild(DialogComponent, { static: false })
  // @ts-ignore
  private dialogComponent: DialogComponent;

  // 言語コード
  language: string = '';

  // dialgo
  dialogInfo: DialogInfo = new DialogInfo();

  constructor(
    public wordInfoService: WordInfoService,
    public loadingService: LoadingService,
    public tabService: TabService,
    public dialogService: DialogService,
    private errorService: ErrorService,
    private initializeService: InitializeService,
    private router: Router
  ) {
    this.loadingService.setLoading = true;
  }

  ngOnInit() {
    // 文言情報取得
    this.language = 'ja';
    // タブの格納
    this.setWordInfo();
  }

  setWordInfo() {
    // Lambda側で処理された結果JSONを取得する
    const response = this.wordInfoService.setWordInfo(this.language);
    response.subscribe(
      // 通信成功時
      (data) => {
        // aws環境ではtop_infoおよび、Top取得時にCookie付与している
        this.wordInfoService.setWordInfoData(data);
        // クッキーのチェック
        // ブラウザのcookie設定をチェックする
        if (this.checkCookieSettings()) {
          return;
        }
        TsLogger().log('word data get end');
      },
      // 通信失敗時
      (error) => {
        TsLogger().log(error);
        this.initializeService.setInitializeCompleteFlg = false;
        this.router.navigate(['/' + Cons.TOP]);
        TsLogger().log('error response ');
      }
    );
  }

  // cookieがブラウザで有効になっているかを確認する。
  checkCookieSettings(): boolean {
    if (navigator.cookieEnabled) {
      // IE,Edge用処理
      const tmp = 'test=' + escape('true');
      window.document.cookie = tmp;
      if (window.document.cookie.indexOf(tmp) == -1) {
        this.dialogInfo = {
          body: 'ckOFF',
          footer: false,
          next: '',
          cookieOff: true,
        };
        this.errorService.setDialogInfo(this.dialogInfo);

        this.router.navigate(['/' + Cons.ERROR], { skipLocationChange: true });

        return true;
      } else {
        console.log('able');
      }
      // cookie 有効
      console.log('able');
    } else {
      this.dialogInfo = {
        body: 'ckOFF',
        footer: false,
        next: '',
        cookieOff: true,
      };
      this.errorService.setDialogInfo(this.dialogInfo);
      this.router.navigate(['/' + Cons.ERROR], { skipLocationChange: true });
      return true;
    }
    return false;
  }

  prepareRoute(outlet: RouterOutlet) {
    // if(event.type && event.type == 'popstate') {
    //  return false;
    // }
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }

  // ダイアログクローズ時の処理
  onDialogClose(dialogInfo: DialogInfo): void {
    this.dialogComponent.onDialogClose(dialogInfo);
    this.dialogService.setDialogInfo = new DialogInfo();
    if (this.dialogService.getCallbackDialog) {
      this.dialogService.getCallbackDialog();
      this.dialogService.setCallbackDialog = () => {};
    }
  }

  onDialogCloseNG(dialogInfo: DialogInfo): void {
    this.dialogComponent.onDialogCloseNG(dialogInfo);
    this.dialogService.setDialogInfo = new DialogInfo();
    if (this.dialogService.getCallbackDialogNG) {
      this.dialogService.getCallbackDialogNG();
      this.dialogService.setCallbackDialogNG = () => {};
    }
  }
}
