import { Util } from '../util/util';
/**
 * デバッグ用のconsole.
 *
 * 開発環境のみconsole機能を提供する。prod環境ではconsoleは動かさない
 * 改善の余地あり
 * @export
 * @returns console|cnsoleのMock
 */
export function TsLogger() {
  if (Util.getlogMode()) {
    return console;
  } else {
    return console;
  }
}

export class Log {}
