import { Injectable } from '@angular/core';

import { TabModel } from '../model/tab-model';

@Injectable({
  providedIn: 'root',
})
export class TabService {
  //タブ群
  private _tabs: Array<TabModel> = new Array();
  constructor() {}

  get tabs(): Array<TabModel> {
    return this._tabs;
  }
  set tabs(tabs: Array<TabModel>) {
    this._tabs = tabs;
  }

  //初期選択のタブを返却する。
  getSelectTabModel = (): Object => {
    for (let i = 0; i <= this._tabs.length; i++) {
      //selectがtrueの場合初期タブとして返却する。
      if (this._tabs[i].getSelect) {
        return this._tabs[i].getContents;
      }
    }
    return this._tabs[0].getContents;
  };

  //選択されたタブに変更する。
  changeSelectTabModel = (name: string): Object => {
    let selectTab: Object = {};
    for (let i = 0; i < this._tabs.length; i++) {
      //selectを全てfalseにしてから
      this._tabs[i].setSelect = false;
      if (this._tabs[i].getName === name) {
        this._tabs[i].setSelect = true;
        selectTab = this._tabs[i].getContents;
      }
    }
    return selectTab;
  };
}
