import { Injectable } from '@angular/core';

import * as Cons from '../constants/constants';
import { TsLogger } from '../log/log';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class AdminCommonService {
  //子テナント名とIDのマップ
  private vendorIdMap: Map<string, string> = new Map();
  //クライアント名とIDのマップ
  private clientIdMap: Map<string, string> = new Map();
  //xsrf対策用のトークンを格納する
  private xsrfToken: string = '';

  constructor(private loadingService: LoadingService) {}

  //sha256を利用してメールアドレスをハッシュ化する。
  //暗号化処理が非同期のためPromiseを利用する。
  createSha256Hash = (str: string) => {
    return new Promise((resolve) => {
      let algolizm = 'SHA-256';
      let msCrypto: Object & { subtle: { digest: Function } } =
        // @ts-ignore
        window['msCrypto'];
      if (msCrypto) {
        //let data = new Uint8Array(str);
        let hash;
        const utf8 = unescape(encodeURIComponent(str));
        let arr = [];
        for (let i = 0; i < utf8.length; i++) {
          arr.push(utf8.charCodeAt(i));
        }
        const data = new Uint8Array(arr);
        let crypto = msCrypto;
        if (crypto.subtle) {
          let oparation = crypto.subtle.digest({ name: algolizm }, data);
          oparation.oncomplete = (
            event: Event & { target: { result: ArrayBuffer } }
          ) => {
            hash = event.target.result;
            resolve(this.toHexCreate(hash));
          };
        }
      } else {
        crypto.subtle
          .digest(algolizm, new TextEncoder().encode(str))
          .then((hash) => {
            resolve(this.toHexCreate(hash));
          });
      }
    });
  };

  toHexCreate = (data: ArrayBuffer) => {
    const byteData = new Uint8Array(data);
    let hexstr: string = '';
    for (let i = 0; i < byteData.length; i++) {
      if (!hexstr) {
        hexstr = ('00' + byteData[i].toString(16)).slice(-2);
      } else {
        hexstr += ('00' + byteData[i].toString(16)).slice(-2);
      }
    }
    return hexstr;
  };

  //配列からメールアドレスのチェック処理
  checkMailAdress = (item: Array<string>): string => {
    const regexp = /^[\\.A-Za-z0-9!#$%&'*+-/=?^_`{|}~\\-]+@[A-Za-z0-9](\.[A-Za-z0-9\\-]|[A-Za-z0-9\\-])*$/i;
    let errorMailAdress: string = '';
    for (let i = 0; i < item.length; i++) {
      if (!item[i].match(regexp)) {
        errorMailAdress += item[i] + '<br>';
      }
    }
    return errorMailAdress;
  };

  //CSVファイルを作成する
  makeCsvFileFunc = (data: Array<string>, clientName: string) => {
    data.sort();
    let filename = clientName + '_' + Cons.USERFILENAME + '.csv';
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    //ファイル上限数より多い場合分けて出力する。
    if (data.length > Cons.FILELISTLIMIT) {
      filename = clientName + '_' + Cons.USERFILENAME;
      let blobList = new Array();
      let dataList: string = '';
      let limitCounter = 1;
      for (let i = 0; i < data.length; i++) {
        dataList += data[i] + '\n';
        if (limitCounter === Cons.FILELISTLIMIT) {
          blobList.push(new Blob([bom, dataList], { type: 'text/csv' }));
          dataList = '';
          limitCounter = 1;
        } else {
          limitCounter++;
        }
      }
      if (dataList.length > 0) {
        blobList.push(new Blob([bom, dataList], { type: 'text/csv' }));
      }
      for (let i = 0; i < blobList.length; i++) {
        //IE10/11用
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(
            blobList[i],
            filename + '_' + (i + 1) + '.csv'
          );
          this.loadingService.setLoading = false;
        } else {
          const url = (window.URL || window.webkitURL).createObjectURL(
            blobList[i]
          );
          const download = document.createElement('a');
          download.href = url;
          download.download = filename + '_' + (i + 1) + '.csv';
          download.click();
          (window.URL || window.webkitURL).revokeObjectURL(url);
          this.loadingService.setLoading = false;
        }
      }
    } else {
      let replaceUserInfo: string = '';
      for (let i = 0; i < data.length; i++) {
        replaceUserInfo += data[i] + '\n';
      }
      const blob = new Blob([bom, replaceUserInfo], { type: 'text/csv' });
      //IE10/11用
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, filename);
        this.loadingService.setLoading = false;
      } else {
        const url = (window.URL || window.webkitURL).createObjectURL(blob);
        const download = document.createElement('a');
        download.href = url;
        download.download = filename;
        download.click();
        setTimeout(() => {
          (window.URL || window.webkitURL).revokeObjectURL(url);
        }, 200);
        this.loadingService.setLoading = false;
      }
    }
  };

  //CSVファイルをオープンして配列に格納する
  openCsvFunc = (file: File) => {
    //サービスとそのサービスにある関数を受け取り実行する。
    return new Promise((resolve) => {
      this.loadingService.setLoading = true;
      let fileReader = new FileReader();
      fileReader.readAsText(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
    });
  };

  //ファイルの拡張子チェックを行う
  checkFileMineType = (files: FileList): boolean => {
    let fileName = files[0].name;
    let index = fileName.lastIndexOf('.');
    let fileType = fileName.slice(index + 1);
    TsLogger().log(fileName);
    if (fileType !== 'csv') {
      return true;
    } else {
      return false;
    }
  };

  //ファイルのサイズをチェックする
  checkFileSize = (file: File): boolean => {
    if (file.size <= 0) {
      return true;
    }
    return false;
  };

  //ボタンの名前を切り取る
  substrButtonName = (str: string): string => {
    if (str != null && str.length > 9) {
      return str.substr(0, 9) + '…';
    } else {
      return str;
    }
  };

  get getVendorIdMap(): Map<string, string> {
    return this.vendorIdMap;
  }
  set setVendorIdMap(item: Map<string, string>) {
    this.vendorIdMap = item;
  }
  get getClientIdMap(): Map<string, string> {
    return this.clientIdMap;
  }
  set setClientIdMap(item: Map<string, string>) {
    this.clientIdMap = item;
  }
  get getXsrfToken(): string {
    return this.xsrfToken;
  }
  set setXsrfToken(token: string) {
    this.xsrfToken = token;
  }
}
