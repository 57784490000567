import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loading = false;
  constructor() {}

  get getLoading(): boolean {
    return this.loading;
  }
  set setLoading(loading: boolean) {
    this.loading = loading;
  }
}
