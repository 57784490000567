<header class="topHeader">
  <h1 class="topHeader_title" *ngIf="wordInfoService.getWordInfo()">
    {{wordInfoService.getWord('appNameStep2')}}</h1>
  <div class="topHeader_dixcription" *ngIf="wordInfoService.getWordInfo()">
    {{wordInfoService.getWord('startMsgStep25')}}
  </div>
</header>
<div class="topMain">
  <div class="topMain_inner"></div>
</div>
<footer class="topFooter">
  <div class="topFooter_inner">
    <button class="startBtn" name="button" (click)="onStart()" type=" button"
      *ngIf="wordInfoService.getWordInfo()">{{wordInfoService.getWord('startBtn')}}</button>
    <div class="copyRight" *ngIf="wordInfoService.getWordInfo()">{{wordInfoService.getWord('copyright')}}
    </div>
    <div class="link softlicense " *ngIf="wordInfoService.getWordInfo()" (click)="toSoftLicense()">
      <span class="softlicense_right">
        {{wordInfoService.getWord('softwarelicenseinfo')}}
      </span>
    </div>
  </div>
</footer>
<div id="script"></div>
<div id="css"></div>
<div id="iframe"></div>