import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { WordInfo } from '../model/word-info';
import { Util } from '../util/util';
import { AccessorService } from './accessor.service';

@Injectable({
  providedIn: 'root',
})
// 文言情報を取り扱うサービス
export class WordInfoService {
  // 指定言語に紐付く文言情報群
  private wordInfo: WordInfo = new WordInfo();

  constructor(public accessorService: AccessorService) {}

  // 各言語の文言情報全量を設定する
  setWordInfo = (lang: string): Observable<WordInfo> => {
    let res: Observable<WordInfo>;

    if (Util.getDevMode()) {
      res = this.accessorService.get(environment.topinfo);
    } else {
      let url =
        'https://' +
        window.location.hostname +
        '/message/message_' +
        lang +
        '.json';
      res = this.accessorService.get(url);
    }
    return res;
  };

  // 現在の文言情報群を取得する
  getWordInfo = (): WordInfo => {
    return this.wordInfo;
  };

  setWordInfoData = (word: WordInfo) => {
    this.wordInfo = word;
  };

  getWord = (word: string) => {
    //@ts-ignore
    return this.wordInfo[word];
  };
}
