import { Component, OnInit } from '@angular/core';

import { DialogInfo } from '../appcommon/model/dialog-info';
import { DialogService } from '../appcommon/service/dialog.service';
import { LogOffService } from '../appcommon/service/log-off.service';
import { WordInfoService } from '../appcommon/service/word-info.service';

@Component({
  selector: 'app-not-found-user',
  templateUrl: './not-found-user.component.html',
  styleUrls: ['./not-found-user.component.scss'],
})
export class NotFoundUserComponent implements OnInit {
  public dialogInfo: DialogInfo = new DialogInfo();

  constructor(
    public dialogService: DialogService,
    private logOffService: LogOffService,
    private wordInfoService: WordInfoService
  ) {}

  ngOnInit(): void {
    this.loadNotFoundUserMessage();
  }

  loadNotFoundUserMessage = () => {
    let msg = this.wordInfoService.getWord('unavailableUserMsg');
    if (msg) {
      this.dialogInfo = {
        body: msg.replace('{0}', 'https://cweb.canon.jp/biz-ij/remoteprint'),
        footer: false,
        next: '',
        replaceFlg: true,
      };
      this.dialogService.setDialogInfo = this.dialogInfo;
      //ダイアログの閉じるクリック後の挙動をLambda式で
      this.dialogService.setCallbackDialog = () => {
        //はいが押下されたときユーザ追加用のcsvファイルを格納するためのpresinedURLを発行する。
        this.logOffService.logOffCanonID();
      };
    } else {
      //メッセージ非同期取得の関係で取得できない場合の対応
      setTimeout(this.loadNotFoundUserMessage, 1000);
    }
  };
}
