export const environment = {
  production: true,
  eulaAgree: false,
  debuglog: false,
  noUserInfo: true,
  timerOn: true,
  topinfo:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/admin-Message/message_ja.json',
  imageId: 'localImageId',
  progressImageUrl: 'dummy',
  createWork: 'dummy',
  sessionUrl: 'du',
  getIniUrl:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/Initialize.json',
  gaId: 'dummy' /** ここから↑の設定値はISP時の資産が使用しているもの **/,
  /** getPresignedUrl→印刷データPUT用URL **/
  getPresignedUrl:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/presinedUrl.json',
  /** KickPrintJobUrl→(get)/user/printers **/
  KickPrintJobUrl:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/kickPrintJob.json',
  /** connectIdUrl→(post)/user/printers **/
  connectIdUrl:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/test.json',
  /** getPrinterSetting→(get)/user/printers/{printerId}/jobsetting **/
  getPrinterSetting:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/getPrinterSetting.json',
  /** getPrinterStatus→(get)/user/printers/{printerId}/status **/
  getPrinterStatus:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/getPrinterStatus.json',
  /** getPrinterJob→(get)/user/jobs/print **/
  getPrinterJob:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/getPrinterJob.json',
  /** inputPrinterInfo→(put)/user/files/{fileId} **/
  inputPrinterInfo:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/inputPrinterInfo.json',
  /** getPrinterList→(get)/user/printers **/
  getPrinterList:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/getPrinterList.json',
  /** プリンタ設定の辞書を取得 **/
  getPrinterWordDict:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/dc_res_ja.json',
  /** プリンタ設定を取得 **/
  getPrinterSettingWord:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/jobsettings.json',
  /** ジョブ履歴を取得 **/
  getJobHistory:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/jobHistory.json',
  /** eulaを取得 **/
  getEula:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/PP_ja.json',
  /** file 完了通知用 **/
  putCompleteFile:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/putCompleateFile.json',
  /** セッションデリート **/
  deleteSession:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/sessionDelete.json',
  /** Eula非同意バージョン **/
  notAgreeEula:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/InitializeNotAgreeEula.json',
  getLogDownLoadURL:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/logdownload.json',
  /** 初期処理のクライアント名を取得する。 */
  getClientName:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/clientName.json',
  getUserInfo:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/getUserInfo.json',
  getNoUserInfo:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/getNoUserInfo.json',
  getPostUserResults:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/postUserInfo.json',
  getDeleteUserResults:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/deleteUserSuccess.json',
  getUserMailAdress:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/getUserMailAdress.json',
  getPostLogDownload:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/postLogDownload.json',
  noClientId:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/noClientId.json',
  existUser:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/existUser.json',
  serverError:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/serverError.json',
  notExistUser:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/notExistUser.json',
  otherClient:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/otherClient.json',
  getXsrfToken:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/getXsrfToken.json',
  getPrintLogs:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/getPrintLog.json',
  getUserListGrid:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/getUserListGrid.json',
  getAdminInfo:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/getAdminInfo.json',
  getAdminMailAdress:
    'https://dps-aaa-angular-localtest.s3.ap-northeast-1.amazonaws.com/local/getAdminMailAdress.json',
};
