//session 保存キー
export const USER_ID: string = 'userId';
export const EVENT_CODE = 'eventCode';
export const WORK_ID = 'workId';
export const FRAME_ID = 'frameId';
export const USER_IMAGE_ID = 'userImageId';
export const PASSWORD = 'password';
export const FRAME_URL = 'frameUrl';
export const IMAGE_ID = 'imageId';
export const OPERATION = 'operation';
export const EDIT_GUIDE = 'editGuide';
export const FACEBOOK_LOCALES = {
  ja: 'ja_JP',
  en: 'en_US',
};
//cookie key
export const SESSION_ID = 'sessionId';

//API
export const TOP_INFO = 'top-info';

//ここからTOPINFo用
export const INITILIZE_INFO = 'initializeInfo';
export const SURVEY_URL = 'surveyUrl';
export const EULA_AGREEMENT_DATE = 'eulaAgreementDate';
export const EXIST_PRINT_CODE = 'existPrintcode';
export const EXISR_PRINT_COMPLETE = 'existPrintComplete';
export const EULA_URL = 'eulaUrl';
export const LANGUAGE_CODE = 'languageCode';
export const BUTTON_STATUS = 'buttonStatus';
export const USER_UPLOAD_STATUS = 'userUploadStatus';
export const WORD_INFO = 'wordInfo';
export const CREATE_USER_ID = 'createUserId';
export const LOGO_IMAGE_URL = 'logoImageUrl';
export const TOP_IMAGE_URL = 'topImageUrl';
export const IS_WORK_EXITS = 'isWorkExist';
export const FIRST_TIME = 'firstTime';
export const GA_ID = 'gaId';

//編集画面
export const IMAGE_INFO = 'imageInfo';

export const LOCAL_CAMERA = 'localCamera';
export const LOCAL_STORAGE = 'localStorage';
export const LOCAL_STORAGE_BY_DIALOG = 'localStorageByDialog';
export const EVENT_BOOTH = 'eventBooth';
export const EXIST_IMAGE = 'existImage';

//edit info
export const EDIT_INFO = 'editInfo';
export const REAL_TRANSLATE_X = 'realTranslateX';
export const REAL_TRANSLATE_Y = 'realTranslateY';
export const ROTATE = 'rotate';
export const SCALE = 'scale';

//printcode data key
export const PRINT_CODE = 'printCode';
export const PRINT_IMAGE_URL = 'printImageUrl';
export const PBOX_LOCATION = 'pboxLocation';

//遷移先一覧
export const TOP = 'top';
export const EULA = 'eula';
export const EULAREAD = 'eularead';
export const LOGIN = 'login';
export const SELECT_FRAME = 'select-frame';
export const SELECT_IMAGE = 'select-image';
export const SELECT_CAMERA_IMAGE = 'select-camera-image';
export const EDIT_WORK = 'edit-work';
export const CREATE_PRINT_CODE = 'create-print-code';
export const ERROR = 'error';
export const WORK_LIST = 'work-list';
export const PRINT_CODE_LIST = 'print-code-list';
export const MAIN = 'main';
export const CART = 'cart';
export const PRINTCODE = 'printcode';
export const HISTORY = 'history';
export const REGIST_TOP = 'regist-top';
export const PRINTERSETTING = 'printersetting';
export const COMPLETE = 'complete';
export const CONNECTID = 'connectid';
export const JOBHISTORY = 'jobhistory';
export const PRIVACYPOLICY = 'privacyPolicy';
export const SETTINGS = 'settings';
export const ADMIN = 'admin';
export const NOTFOUNDUSER = 'notfounduser';
export const SOFTLICENSE = 'softlicense';

// パスワード関連
export const PASSWORD_LENGTH_MIN = 4;
export const PASSWORD_LENGTH_MAX = 10;

//v110
export const IS_NO_PASSWORD = 'isNoPassword';

export const NO_PASSWORD_MODE = 'noPasswordMode';

export const EXIF_MODE = 'exifMode';
export const PRINT_THUMB_URL = 'printThumbnailUrl';

//main画面用

export const MAIN_WORK_LIST = 'main-work-list';
export const MAIN_IMAGE_LIST = 'main-image-list';
export const POLLING_WORK_LIST = 'polling-work-list';
export const SCROLL_HEIGHT = 'scrollHeight';
export const PRINT_WORK_COUNT_LIMIT = 'printWorkCountLimit';
export const IS_UPLOAD_TERM = 'isUploadTerm';
export const PRINT_STATUS = 'printStatus';
export const IMAGE_UPLOAD_STATUS = 'imageUploadStatus';
export const IS_SENDABLE = 'isSendable';
export const IMAGE_INFO_LIST = 'imageInfoList';
export const WORK_INFO_LIST = 'workInfoList';
export const MAIN_PAGE_PARAM = 'mainPageParam';
export const THUMB_URL = 'thumbnailUrl';

// カート画面用
export const CART_INFO_LIST = 'cartInfoList';
export const CONSUMPTION_TAX = 'taxtotal';
export const SUBTOTAL = 'subtotal';
export const TOTAL = 'total';

// 履歴
export const PRINTCODE_INFO_LIST = 'printcodeInfoList';
export const NUM = 'num';

//prameter
export const COOKIE_CHECK_USER = 'cookieCheckUser';
export const COOKIE_DELETE_USER = 'cookieDeleteUser';

//言語(優先度順)
export const LANGUAGE_LIST = ['en', 'ja'];
//GA
export const GA_TOP = 'top';
export const GA_MAIN = 'main';
export const GA_EDIT_WORK = 'edit-work';

export const GA_BUTTON = 'Button';
export const GA_SELECT_BOX = 'SelectBox';
export const GA_LINK = 'Link';

export const GA_START = 'Start';
export const GA_SELECT_LANGUAGE = 'SelectLanguage';
export const GA_OPEN_SURVEY = 'OpenSurvey';
export const GA_OPEN_EULA = 'OpenEula';
export const GA_AGREE_EULA = 'AgreeEula';
export const GA_CONFIRM_PASSWORD = 'ConfirmPassword';
export const GA_CONFIRM_PASSWORD_WARNING = 'ConfirmPasswordWarning';
export const GA_AUTHENTICATE = 'Authenticate';
export const GA_PUBLISH_PRINTCODE = 'PublishPrintcode';
export const GA_OPEN_PHOTO_INFORMATION = 'OpenPhotoInformation';
export const GA_DOWNLOAD_IMAGE = 'DownloadImage';
export const GA_EDIT = 'Edit';
export const GA_ROTATE_PHOTO = 'RotatePhoto';
export const GA_SAVE_IMAGE = 'SaveImage';

export const GA_UPLOAD_PHOTO_BY_MAIN = 'UploadPhotoByMain';
export const GA_UPLOAD_PHOTO_BY_DIALOG = 'UploadPhotoByDialog';

//在宅プリント
export const MEDIATYPECLASS = 'MediaTypeClass';
export const OUTPUTMEDIASIZE = 'OutputMediaSize';
export const COPIES = 'Copies';
export const DUPLEX = 'Duplex';
export const DOCUMENTNUP = 'DocumentNUP';
export const VALUES = 'values';
export const VALUE = 'value';
export const PRINTERID = 'printerId';
export const PRINTERNAME = 'modelName';

// initialize
export const AGREED = 'agreed';
export const UPDATEDATE = 'updateDate';

//bytes
export const GB = 1073741824;
export const MB = 1048576;
export const KB = 1024;

//httpメソッド名
export const POST = 'POST';
export const DELETE = 'DELETE';

//タブ名
export const ADDTAB = '追加';
export const DELETETAB = '削除';
export const LOGDOWNLOADTAB = 'ログダウンロード';

//指定月ドロップダウン生成用
export const YEAR = '年';
export const MONTH = '月';

//ログファイル作成ステータス
export const CREATE_LOG_CONVERTED = 'converted';
export const CREATE_LOG_CONVERTING = 'converting';

//ユーザ一覧ファイル名
export const USERFILENAME = 'ユーザ一覧';
//ファイルリスト上限数
export const FILELISTLIMIT = 1000;
//ユーザ追加のhttp通信並列処理制御数CSVファイルの分割数
export const PARALLELCOUNTER = 1;

//prdのホスト名
export const PRDHOSTNAME = 'remotework-admin-trial.c-ij.com';

//セッションが切れた場合のレスポンスプロパティ名
export const SESSIONEXPIRED = 'sessionExpired';

//ユーザ追加時のエラー
export const POSTUSERERRORMAP = ['noClientId', 'existUser', 'serverError'];

//上記エラーと文言表のマッピング調整
export const POSTUSERERRORMAPMESSAGE = new Map([
  ['noClientId', 'errorNoClientId'],
  ['existUser', 'errorExistUser'],
  ['serverError', 'errorServer'],
]);

//ユーザ削除時のエラー
export const DELETEUSERMAP = ['notExistUser', 'otherClientUser', 'serverError'];

//上記エラーと文言表のマッピング調整
export const DELETEUSERMAPMESSAGE = new Map([
  ['notExistUser', 'errorNotExistUser'],
  ['otherClientUser', 'errorOtherClient'],
  ['serverError', 'errorServer'],
]);

//管理者削除時のエラー
export const DELETEADMINMAP = [
  'denyDeleteMyself',
  'notExistAdmin',
  'otherClientAdmin',
  'serverError',
];

//上記エラーと文言表のマッピング調整
export const DELETEADMINMAPMESSAGE = new Map([
  ['denyDeleteMyself', 'errorServer'],
  ['notExistUser', 'errorNotExistUser'],
  ['otherClientUser', 'errorOtherClient'],
  ['serverError', 'errorServer'],
]);

//ウィンドウズファイル名禁止文字列
export const WINDOWSPROHIBITCHARACTOR = [
  'AUX',
  'COM0',
  'COM1',
  'COM2',
  'COM3',
  'COM4',
  'COM5',
  'COM6',
  'COM7',
  'COM8',
  'COM9',
  'CON',
  'LPT0',
  'LPT1',
  'LPT2',
  'LPT3',
  'LPT4',
  'LPT5',
  'LPT6',
  'LPT7',
  'LPT8',
  'LPT9',
  'NUL',
  'PRN',
];

export const SUMNAIL = 'サムネイル';
export const LOGID = 'ログID';
export const CLIENTNAME = 'クライアント名';
export const SERIALNUMBER = 'シリアルナンバー';
export const MODELNAME = 'モデル名';
export const MAILADDRESS = 'メールアドレス';
export const JOBTYPE = 'ジョブタイプ';
export const STARTTIME = '開始時刻';
export const ENDTIME = '終了時刻';
export const JOBSTATUS = 'ジョブの状態';
export const FILENAME = 'ファイル名';
export const FILEFORMAT = 'ファイルフォーマット';
export const FILEPAGENUM = 'ファイルページ数';
export const FILEUPLOADTIME = 'ファイルアップロード時刻';
export const PAPERSIZE = '用紙サイズ';
export const PAPERTYPE = '用紙タイプ';
export const COLORMODE = 'カラーモード';
export const LAYOUT = 'レイアウト';
export const NUMBEROFCOPIES = '部数';
export const ONEORTWOSIDE = '片面/両面';
export const PRINTRANGE = '印刷範囲';
export const ALLPRINTNUM = '全印刷枚数';
export const ALLPAPERNUM = '全用紙枚数';
export const ACTUALPRINTNUM = '実印刷枚数';
export const ACTUALPAPERNUM = '実用紙枚数';
export const GROUP = 'グループ';
export const FREEA = 'フリーA';
export const FREEB = 'フリーB';
export const FREEC = 'フリーC';

export const AG_GRID_LOCALE_JA = {
  // Set Filter
  selectAll: '(Select All)',
  selectAllSearchResults: '(Select All Search Results)',
  searchOoo: 'Search...',
  blanks: '(Blanks)',
  noMatches: 'No matches',

  // Number Filter & Text Filter
  filterOoo: 'Filter...',
  equals: 'Equals',
  notEqual: 'Not equal',
  empty: 'Choose One',

  // Number Filter
  lessThan: 'Less than',
  greaterThan: 'Greater than',
  lessThanOrEqual: 'Less than or equal',
  greaterThanOrEqual: 'Greater than or equal',
  inRange: 'In range',
  inRangeStart: 'to',
  inRangeEnd: 'from',

  // Text Filter
  contains: 'contains',
  notContains: 'Not contains',
  startsWith: 'Starts with',
  endsWith: 'Ends with',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',

  // Filter Conditions
  andCondition: 'AND',
  orCondition: 'OR',

  // Filter Buttons
  applyFilter: 'Apply',
  resetFilter: 'Reset',
  clearFilter: 'Clear',
  cancelFilter: 'Cancel',

  // Filter Titles
  textFilter: 'Text Filter',
  numberFilter: 'Number Filter',
  dateFilter: 'Date Filter',
  setFilter: 'Set Filter',

  // Side Bar
  columns: 'Columns',
  filters: 'Filters',

  // columns tool panel
  pivotMode: 'Pivot Mode',
  groups: 'Row Groups',
  rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
  values: 'Values',
  valueColumnsEmptyMessage: 'Drag here to aggregate',
  pivots: 'Column Labels',
  pivotColumnsEmptyMessage: 'Drag here to set column labels',

  // Header of the Default Group Column
  group: 'Group',

  // Other
  loadingOoo: 'Loading...',
  noRowsToShow: 'No Rows To Show',
  enabled: 'Enabled',

  // Menu
  pinColumn: 'Pin Column',
  pinLeft: 'Pin Left',
  pinRight: 'Pin Right',
  noPin: 'No Pin',
  valueAggregation: 'Value Aggregation',
  autosizeThiscolumn: 'Autosize This Column',
  autosizeAllColumns: 'Autosize All Columns',
  groupBy: 'Group by',
  ungroupBy: 'Un-Group by',
  resetColumns: 'Reset Columns',
  expandAll: 'Expand All',
  collapseAll: 'Close All',
  copy: 'Copy',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'Copy With Headers',
  paste: 'Paste',
  ctrlV: 'Ctrl+V',
  export: 'Export',
  csvExport: 'CSV Export',
  excelExport: 'Excel Export (.xlsx)',
  excelXmlExport: 'Excel Export (.xml)',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Sum',
  min: 'Min',
  max: 'Max',
  none: 'None',
  count: 'Count',
  avg: 'Average',
  filteredRows: 'Filtered',
  selectedRows: 'Selected',
  totalRows: 'Total Rows',
  totalAndFilteredRows: 'Rows',
  more: 'More',
  to: 'to',
  of: 'of',
  page: 'Page',
  nextPage: 'Next Page',
  lastPage: 'Last Page',
  firstPage: 'First Page',
  previousPage: 'Previous Page',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
  pivotChart: 'Pivot Chart',
  chartRange: 'Chart Range',

  columnChart: 'Column',
  groupedColumn: 'Grouped',
  stackedColumn: 'Stacked',
  normalizedColumn: '100% Stacked',

  barChart: 'Bar',
  groupedBar: 'Grouped',
  stackedBar: 'Stacked',
  normalizedBar: '100% Stacked',

  pieChart: 'Pie',
  pie: 'Pie',
  doughnut: 'Doughnut',

  line: 'Line',

  xyChart: 'X Y (Scatter)',
  scatter: 'Scatter',
  bubble: 'Bubble',

  areaChart: 'Area',
  area: 'Area',
  stackedArea: 'Stacked',
  normalizedArea: '100% Stacked',

  histogramChart: 'Histogram',

  // Charts
  pivotChartTitle: 'Pivot Chart',
  rangeChartTitle: 'Range Chart',
  settings: 'Settings',
  data: 'Data',
  format: 'Format',
  categories: 'Categories',
  defaultCategory: '(None)',
  series: 'Series',
  xyValues: 'X Y Values',
  paired: 'Paired Mode',
  axis: 'Axis',
  navigator: 'Navigator',
  color: 'Color',
  thickness: 'Thickness',
  xType: 'X Type',
  automatic: 'Automatic',
  category: 'Category',
  number: 'Number',
  time: 'Time',
  xRotation: 'X Rotation',
  yRotation: 'Y Rotation',
  ticks: 'Ticks',
  width: 'Width',
  height: 'Height',
  length: 'Length',
  padding: 'Padding',
  spacing: 'Spacing',
  chart: 'Chart',
  title: 'Title',
  titlePlaceholder: 'Chart title - double click to edit',
  background: 'Background',
  font: 'Font',
  top: 'Top',
  right: 'Right',
  bottom: 'Bottom',
  left: 'Left',
  labels: 'Labels',
  size: 'Size',
  minSize: 'Minimum Size',
  maxSize: 'Maximum Size',
  legend: 'Legend',
  position: 'Position',
  markerSize: 'Marker Size',
  markerStroke: 'Marker Stroke',
  markerPadding: 'Marker Padding',
  itemSpacing: 'Item Spacing',
  itemPaddingX: 'Item Padding X',
  itemPaddingY: 'Item Padding Y',
  layoutHorizontalSpacing: 'Horizontal Spacing',
  layoutVerticalSpacing: 'Vertical Spacing',
  strokeWidth: 'Stroke Width',
  offset: 'Offset',
  offsets: 'Offsets',
  tooltips: 'Tooltips',
  callout: 'Callout',
  markers: 'Markers',
  shadow: 'Shadow',
  blur: 'Blur',
  xOffset: 'X Offset',
  yOffset: 'Y Offset',
  lineWidth: 'Line Width',
  normal: 'Normal',
  bold: 'Bold',
  italic: 'Italic',
  boldItalic: 'Bold Italic',
  predefined: 'Predefined',
  fillOpacity: 'Fill Opacity',
  strokeOpacity: 'Line Opacity',
  histogramBinCount: 'Bin count',
  columnGroup: 'Column',
  barGroup: 'Bar',
  pieGroup: 'Pie',
  lineGroup: 'Line',
  scatterGroup: 'X Y (Scatter)',
  areaGroup: 'Area',
  histogramGroup: 'Histogram',
  groupedColumnTooltip: 'Grouped',
  stackedColumnTooltip: 'Stacked',
  normalizedColumnTooltip: '100% Stacked',
  groupedBarTooltip: 'Grouped',
  stackedBarTooltip: 'Stacked',
  normalizedBarTooltip: '100% Stacked',
  pieTooltip: 'Pie',
  doughnutTooltip: 'Doughnut',
  lineTooltip: 'Line',
  groupedAreaTooltip: 'Area',
  stackedAreaTooltip: 'Stacked',
  normalizedAreaTooltip: '100% Stacked',
  scatterTooltip: 'Scatter',
  bubbleTooltip: 'Bubble',
  histogramTooltip: 'Histogram',
  noDataToChart: 'No data available to be charted.',
  pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
};
