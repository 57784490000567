import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { WordInfoService } from '../appcommon/service/word-info.service';
import * as Cons from '../appcommon/constants/constants';
import { LoadingService } from '../appcommon/service/loading.service';

@Component({
  selector: 'app-software-license',
  templateUrl: './software-license.component.html',
  styleUrls: ['./software-license.component.scss'],
})
export class SoftwareLicenseComponent implements AfterViewInit {
  constructor(
    public wordInfoService: WordInfoService,
    private router: Router,
    private loadingService: LoadingService
  ) {}

  ngAfterViewInit() {
    this.loadingService.setLoading = false;
  }

  toTop() {
    this.router.navigate([Cons.TOP]);
  }
}
