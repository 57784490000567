import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';

import * as Cons from '../constants/constants';
import { TsLogger } from '../log/log';
import { Util } from '../util/util';
import { AccessorService } from './accessor.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  //ログダウンロード用指定月
  private designatedMonth: string = '';

  private monthData:Map<string,string> = new Map([
      ["0", "January"],
      ["1", "February"],
      ["2", "March"],
      ["3", "April"],
      ["4", "May"],
      ["5", "June"],
      ["6", "July"],
      ["7", "August"],
      ["8", "September"],
      ["9", "October"],
      ["10", "November"],
      ["11", "December"],
      ["January", "1"],
      ["February", "2"],
      ["March", "3"],
      ["April", "4"],
      ["May", "5"],
      ["June", "6"],
      ["July", "7"],
      ["August", "8"],
      ["September", "9"],
      ["October", "10"],
      ["November", "11"],
      ["December", "12"]
    ]);

  constructor(private accessorService: AccessorService) {}

  //ダウンロード用のURLを取得する。
  getLogInfo = (
    printLogDownloadId: string
  ): Observable<{ printLogDownloadStatus: string; url: string }> => {
    // とりあえず同じURLを呼ぶものだけでも共通化する。
    let response: Observable<{ printLogDownloadStatus: string; url: string }>;
    if (Util.getDevMode()) {
      TsLogger().log('プリントログIDのテスト中');
      TsLogger().log(printLogDownloadId);
      response = this.accessorService.get(environment.getLogDownLoadURL);
    } else {
      response = this.accessorService.get(Util.logFileUrl(printLogDownloadId));
    }
    return response;
  };

  //logFile作成を実行する
  //user情報を登録する。
  postLogfileCreateService = (): Observable<{ printLogDownloadId: string }> => {
    // とりあえず同じURLを呼ぶものだけでも共通化する。
    let response: Observable<{ printLogDownloadId: string }>;
    if (Util.getDevMode()) {
      TsLogger().log(this.monthCheck(this.designatedMonth));
      let yearMonth = this.monthCheck(this.designatedMonth);
      let startDate = this.getUNIX( Number(yearMonth[0]), Number(yearMonth[1])-1, 1);
      let endDate = this.getUNIX( Number(yearMonth[0]), Number(yearMonth[1]), 1);
      console.log('startdate:'+ startDate );
      console.log('enddate:'+ endDate );
      response = this.accessorService.get(environment.getPostLogDownload);
    } else {
      let yearMonth = this.monthCheck(this.designatedMonth);
      let startDate = this.getUNIX( Number(yearMonth[0]), Number(yearMonth[1])-1, 1);
      let endDate = this.getUNIX( Number(yearMonth[0]), Number(yearMonth[1]), 1);
      console.log('startdate:'+ startDate );
      console.log('enddate:'+ endDate );
      response = this.accessorService.post(
        Util.logFileUrl(),
        JSON.stringify({
          start: startDate,
          end: endDate,
          recursive: true,
        })
      );
    }
    return response;
  };

  //Unixタイムスタンプ作成
  getUNIX = (year:number, month:number, day:number): number =>  {
    let date = new Date(year,month,day)
    return Math.floor(date.getTime()/1000);
  }

  monthCheck = (month: string): string[] => {
    let yearMonth: Array<string> = new Array();
    let yyyy = month.split(' ')[1];
    console.log(yyyy)
    yearMonth.push(yyyy);
    //mmは「年1」となっている場合もあるためチェックする。

    let mm = this.monthData.get(month.split(' ')[0]);
    if (mm == undefined) {
      mm = '';
    }
    yearMonth.push(mm);
    console.log(yearMonth)
    return yearMonth;
  };

  get getDesignatedMonth(): string {
    return this.designatedMonth;
  }
  set setDesignatedMonth(month: string) {
    this.designatedMonth = month;
  }

  get getMonthData():Map<string,string>{
    return this.monthData;
  }
}
