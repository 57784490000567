<div class="layout" *ngIf="initializeService.getInitializeCompleteFlg==true">
  <app-side-menu [nowPrint]="true"></app-side-menu>
  <div class="layout_main">
    <app-header [firstTime]="false"></app-header>
    <div class="outer">
      <div class="inner-minwidth-1000">
        <app-log-download></app-log-download>
      </div>
    </div>
  </div>
</div>