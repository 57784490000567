import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LogOffService } from '../appcommon/service/log-off.service';
import { WordInfoService } from '../appcommon/service/word-info.service';
import * as Cons from '../appcommon/constants/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // 親コンポーネントから渡される入力ダイアログ情報
  @Input() firstTime: boolean = false;
  @Input() nowJobhistory: boolean = false;
  @Input() nowSetting: boolean = false;

  constructor(
    private router: Router,
    public wordInfoService: WordInfoService,
    public logOffService: LogOffService
  ) {}

  ngOnInit(): void {}

  onLogout = () => {
    this.logOffService.deleteSession();
  };

  onLogo() {
    this.transition_pages(Cons.ADMIN);
  }

  // 移動
  transition_pages(nextPage: string) {
    this.router.navigate(['/' + nextPage]);
  }
}
