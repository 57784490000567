import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { DialogService } from '../../appcommon/service/dialog.service';
import * as Cons from '../constants/constants';
import { TsLogger } from '../log/log';
import { DialogInfo } from '../model/dialog-info';
import { AccessorService } from '../service/accessor.service';
import { Util } from '../util/util';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root',
})
export class LogOffService {
  // エラーダイアログ用オブジェクト
  dialogInfo: DialogInfo = new DialogInfo();

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private accessorService: AccessorService,
    private errorService: ErrorService
  ) {}

  deleteSession = (): void => {
    //AAAのログインセッションを無効にする。
    let url = '';
    let res;
    if (Util.getDevMode()) {
      url = environment.deleteSession;
      res = this.accessorService.get(url);
    } else {
      let header: Map<string, string> = new Map<string, string>();
      header.set('Cache-Control', 'max-age=86400');
      url = Util.getSessionUrl();
      res = this.accessorService.delete(url, header);
    }
    res.subscribe(
      (data) => {
        //sessionのチェック
        let dialogInfo = Util.getSessionErrorDialog(data);
        if (dialogInfo.body) {
          this.dialogService.setDialogInfo = dialogInfo;
          return;
        }
        TsLogger().info(data);
        this.logOffCanonID();
      },
      (error) => {
        TsLogger().info('user session is NG' + JSON.stringify(error));
        this.logOffCanonID();
      }
    );
  };

  deleteUser = (): void => {
    let url = '';
    let res;
    let header: Map<string, string> = new Map<string, string>();
    header.set('Cache-Control', 'max-age=86400');
    if (Util.getDevMode()) {
      url = environment.deleteSession;
      res = this.accessorService.get(url);
    } else {
      url = Util.getUserUrl();
      res = this.accessorService.delete(url, header);
    }
    res.subscribe(
      (data) => {
        //sessionのチェック
        let dialogInfo = Util.getSessionErrorDialog(data);
        if (dialogInfo.body) {
          this.dialogService.setDialogInfo = dialogInfo;
          return;
        }
        TsLogger().info(data);
        this.logOffCanonID();
      },
      (error) => {
        TsLogger().info('user session is NG' + JSON.stringify(error));
        this.dialogInfo = Util.getDialogInfoError(error);

        // ダイアログを閉じたときに呼ばれるFunction定義
        this.errorService.setDialogInfo(this.dialogInfo);
        this.router.navigate(['/' + Cons.ERROR], { skipLocationChange: true });
      }
    );
  };

  logOffCanonID = (): void => {
    // 成功の場合CanonIDのログオフを行う
    if (Util.getDevMode()) {
      this.router.navigate(['/' + Cons.ADMIN]);
    } else {
      const topUrl = 'https://' + window.location.host + '/';
      let canonLogoutUrl = '';
      canonLogoutUrl = Util.getLogoutCanonIdURL(topUrl);
      TsLogger().info(canonLogoutUrl);
      location.href = canonLogoutUrl;
    }
  };
}
