import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
})
export class SelectBoxComponent implements OnInit, AfterViewChecked {
  @Input() itemList: Array<string> = new Array();
  @Input() imageOffFlag: boolean = false;
  @Output() itemValue = new EventEmitter<string>();
  @Output() startFlg = new EventEmitter<string>();

  constructor() {}
  ngOnInit(): void {}

  ngAfterViewChecked() {
    this.startFlg.emit();
  }

  onItem = (value: string): void => {
    this.itemValue.emit(value);
  };

  onClose = (): void => {
    this.itemValue.emit('');
  };
}
