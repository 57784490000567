import { Component, OnInit } from '@angular/core';

import { DialogInfo } from '../appcommon/model/dialog-info';
import { DialogService } from '../appcommon/service/dialog.service';
import { ErrorService } from '../appcommon/service/error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit {
  public dialogInfo: DialogInfo = new DialogInfo();

  constructor(
    public dialogService: DialogService,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
    this.dialogService.setDialogInfo = this.errorService.getDialogInfo();
  }
}
