<div *ngIf="dialogService.getDialogInfo.body" class="outer-dialog">
  <div class="dialog">
    <div *ngIf="wordInfoService.getWordInfo() && !dialogService.getDialogInfo.replaceFlg" class="dialog_body"
      [innerHtml]="wordInfoService.getWord(dialogService.getDialogInfo.body)"></div>
    <div *ngIf="wordInfoService.getWordInfo() && dialogService.getDialogInfo.replaceFlg" class="dialog_body"
      [innerHtml]="dialogService.getDialogInfo.body"></div>
    <div class="dialog_footer" *ngIf="!dialogService.getDialogInfo.cookieOff">
      <div *ngIf="wordInfoService.getWordInfo() && !dialogService.getDialogInfo.footer" class="dialog_footer_item"
        (click)="clickFooter_OK()">{{wordInfoService.getWord('okBtn')}}</div>
      <div *ngIf="wordInfoService.getWordInfo() && dialogService.getDialogInfo.footer" class="dialog_footer_left"
        (click)="clickFooter_NG()">{{wordInfoService.getWord('unregistNO')}}</div>
      <div *ngIf="wordInfoService.getWordInfo() && dialogService.getDialogInfo.footer" class="dialog_footer_right"
        (click)="clickFooter_OK()">{{wordInfoService.getWord('unregistOK')}}</div>
    </div>
  </div>
</div>