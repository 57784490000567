import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppcommonModule } from '../appcommon.module';
import { TsLogger } from '../log/log';
import { Util } from '../util/util';

@Injectable({
  providedIn: AppcommonModule,
})
export class AccessorService {
  constructor(private http: HttpClient) {}

  get<T>(
    requestUrl: string,
    requestHeader = new Map<string, string>()
  ): Observable<T> {
    //countだけ持たせてみる
    //this.messageService.add('HeroService: fetched heroes' +　this.count);
    //return of(HEROES);
    let newheaders = new HttpHeaders();
    TsLogger().log('header = ' + requestHeader.keys());
    // ログの追加
    requestHeader.forEach((value: string, key: string) => {
      TsLogger().log(key + '=' + value);
      newheaders = newheaders.set(key, value);
    });
    //Common Header add
    if (Util.getDevMode()) {
      newheaders = newheaders.set('Access-Control-Allow-Origin', '*');
    }

    if (!newheaders.has('Content-Type')) {
      newheaders = newheaders.set(
        'Content-Type',
        'application/json; charset=utf-8'
      );
    }

    let response: Observable<T> = this.http.get<T>(requestUrl, {
      headers: newheaders,
    });
    TsLogger().log(response);

    return response;
  }

  post<T>(
    requestUrl: string,
    body: string,
    requestHeader = new Map<string, string>()
  ): Observable<T> {
    let newheaders = new HttpHeaders();
    TsLogger().log('header = ' + requestHeader.keys());
    // ログの追加
    requestHeader.forEach((value: string, key: string) => {
      TsLogger().log(key + '=' + value);
      newheaders = newheaders.set(key, value);
    });
    //Common Header add
    if (Util.getDevMode()) {
      newheaders = newheaders.set('Access-Control-Allow-Origin', '*');
    }
    if (!newheaders.has('Content-Type')) {
      newheaders = newheaders.set(
        'Content-Type',
        'application/json; charset=utf-8'
      );
    }

    let response: Observable<T> = this.http.post<T>(requestUrl, body, {
      headers: newheaders,
    });
    TsLogger().log(response);

    return response;
  }

  put<T>(
    requestUrl: string,
    body: string | FormData | File,
    requestHeader = new Map<string, string>()
  ): Observable<T> {
    let newheaders = new HttpHeaders();
    TsLogger().log('header = ===' + requestHeader.keys());
    // ログの追加
    requestHeader.forEach((value: string, key: string) => {
      TsLogger().log(key + '=' + value);
      newheaders = newheaders.set(key, value);
    });
    //Common Header add
    if (Util.getDevMode()) {
      newheaders = newheaders.set('Access-Control-Allow-Origin', '*');
    }

    if (!newheaders.has('Content-Type')) {
      newheaders = newheaders.set(
        'Content-Type',
        'application/json; charset=utf-8'
      );
    }

    let response: Observable<T> = this.http.put<T>(requestUrl, body, {
      headers: newheaders,
    });
    TsLogger().log(response);

    return response;
  }

  delete<T>(
    requestUrl: string,
    requestHeader = new Map<string, string>()
  ): Observable<T> {
    let newheaders = new HttpHeaders();
    TsLogger().log('header = ' + requestHeader.keys());
    // ログの追加
    requestHeader.forEach((value: string, key: string) => {
      TsLogger().log(key + '=' + value);
      newheaders = newheaders.set(key, value);
    });
    //Common Header add
    if (Util.getDevMode()) {
      newheaders = newheaders.set('Access-Control-Allow-Origin', '*');
    }

    if (!newheaders.has('Content-Type')) {
      newheaders = newheaders.set(
        'Content-Type',
        'application/json; charset=utf-8'
      );
    }

    let response: Observable<T> = this.http.delete<T>(requestUrl, {
      headers: newheaders,
    });
    TsLogger().log(response);

    return response;
  }
}
