import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { DialogInfo } from '../../appcommon/model/dialog-info';
import { DialogService } from '../../appcommon/service/dialog.service';
import { LogOffService } from '../../appcommon/service/log-off.service';
import { WordInfoService } from '../../appcommon/service/word-info.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
})
export class DialogComponent implements OnInit {
  //footerがnullの場合ボタンをなくす
  // 親コンポーネントに返却するもの※ダイアログを閉じた通知のみ渡す
  @Output() dialogClose = new EventEmitter<DialogInfo>();
  @Output() dialogCloseNG = new EventEmitter<DialogInfo>();

  constructor(
    public wordInfoService: WordInfoService,
    public loggOffService: LogOffService,
    public dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit() {}

  clickFooter_OK = (): void => {
    // 親コンポーネントにフッターボタンが押されたことのみ通知
    this.dialogClose.emit(this.dialogService.getDialogInfo);
  };

  clickFooter_NG = (): void => {
    // 親コンポーネントにフッターボタンが押されたことのみ通知
    this.dialogCloseNG.emit(this.dialogService.getDialogInfo);
  };

  // ダイアログクローズ時の処理
  onDialogClose = (dialogInfo: DialogInfo): void => {
    if (this.dialogService.getDialogInfo.next) {
      if (new RegExp('^http.*').test(this.dialogService.getDialogInfo.next)) {
        location.href = this.dialogService.getDialogInfo.next;
      } else {
        this.transitionPages(this.dialogService.getDialogInfo.next);
      }
    }
  };

  // ダイアログクローズ時の処理
  onDialogCloseNG = (dialogInfo: DialogInfo): void => {
    if (this.dialogService.getDialogInfo.nextNG) {
      if (new RegExp('^http.*').test(this.dialogService.getDialogInfo.nextNG)) {
        this.loggOffService.deleteSession();
        //location.href = this.dialogInfo.nextNG;
      } else {
        this.transitionPages(this.dialogService.getDialogInfo.nextNG);
      }
    }
  };

  // 移動
  transitionPages = (nextPage: string) => {
    this.router.navigate(['/' + nextPage]);
  };
}
