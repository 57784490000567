import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as Cons from '../constants/constants';
import { Util } from '../util/util';
import { AccessorService } from './accessor.service';
import { AdminCommonService } from './admin-common.service';
import { ClientService } from './client.service';
import { DialogService } from './dialog.service';
import { InitializeService } from './initialize.service';
import { LoadingService } from './loading.service';
import { LogService } from './log.service';
import { UserService } from './user.service';
import { VendorService } from './vendor.service';
import { TsLogger } from '../log/log';
@Injectable({
  providedIn: 'root',
})
export class DropBoxService {
  //ユーザ登録のドロップボックス設定
  private registUserforVendorFlg: boolean = false;
  //ユーザ登録のドロップボックス設定
  private registUserFlg: boolean = false;
  //子テナント削除のドロップボックス設定
  private deleteVendorFlg: boolean = false;
  //子テナント管理者検索のドロップボックス設定
  private searchVendorAdminFlg: boolean = false;
  //子テナント管理者登録のドロップボックス設定
  private registVendorAdminFlg: boolean = false;
  //子テナント管理者削除のドロップボックス設定
  private deleteVendorAdminFlg: boolean = false;
  //クライアント登録のドロップボックス設定
  private registClientFlg: boolean = false;
  //クライアント削除のドロップボックス設定
  private deleteClientforVendorFlg: boolean = false;
  //クライアント削除のドロップボックス設定
  private deleteClientFlg: boolean = false;
  //ユーザ削除のドロップボックス設定
  private deleteUserforVendorFlg: boolean = false;
  //ユーザ削除のドロップボックス設定
  private deleteUserFlg: boolean = false;
  //ユーザ検索のドロップダウン設定
  private searchUserforVendorFlg: boolean = false;
  //ユーザ検索のドロップボックス設定
  private searchUserFlg: boolean = false;
  //指定月のドロップボックス設定
  private designatedFlg: boolean = false;
  //指定月のリスト
  private designatedMonthList: Array<string> = new Array();
  //子テナント名リスト
  private vendorNameList: Array<string> = new Array();
  //クライアント名リスト
  private clientNameList: Array<string> = new Array();
  //ボタン操作が行われたときの子テナント名
  private selectVendorName: string = '';
  //ボタン操作が行われたときのクライアント名
  private selectClientName: string = '';

  constructor(
    private initializeService: InitializeService,
    private accessorService: AccessorService,
    private clientService: ClientService,
    private userService: UserService,
    private logService: LogService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private adminCommonService: AdminCommonService,
    private vendorService: VendorService,
    private router: Router
  ) {}

  //クライアント名のリスト取得のURL設定
  setClientNameListUrl = (
    vendorName: string
  ): Observable<{
    clientInfo: { clientId: string; clientName: string }[];
  }> => {
    let response: Observable<{
      clientInfo: { clientId: string; clientName: string }[];
    }>;
    if (Util.getDevMode()) {
      response = this.accessorService.get(environment.getClientName);
    } else {
      //アップロードするPreSignedUrlを取得する
      response = this.accessorService.get(
        Util.getTenantClientInfoUrl(
          this.adminCommonService.getVendorIdMap.get(vendorName)
        )
      );
    }
    return response;
  };

  //クライアント名のリストを取得し、設定する。
  getClientNameListService = (vendorName: string): void => {
    //initializeFlgは画面を開いたときにadminからのみtrueで呼ばれる初回の呼び出し
    this.loadingService.setLoading = true;
    let response = this.setClientNameListUrl(vendorName);
    response.subscribe(
      // 通信成功時
      (data) => {
        //sessionのチェック
        let dialogInfo = Util.getSessionErrorDialog(data);
        if (dialogInfo.body) {
          this.dialogService.setDialogInfo = dialogInfo;
          return;
        }
        //変数を設定
        this.createClientInfoList(data['clientInfo']);
        this.clientNameList.sort((x, y) => x.localeCompare(y, 'ja'));
        if (this.clientNameList.length > 0) {
          this.setFirstClientName();
        }
        this.loadingService.setLoading = false;

        //クライアント登録時の処理クライアント名が残っている場合セレクトクライアント名に設定してNULLにする
        if (this.clientService.getClientRegisterName) {
          this.setSelectClientName = this.clientService.getClientRegisterName;
          this.clientService.setClientRegisterName = '';
        }
      },
      // 通信失敗時
      (error) => {
        if (error.status == 0) {
          this.dialogService.setDialogInfo = {
            body: 'errUploadTimeout',
            footer: false,
            next: '',
          };
          return;
        }
        this.loadingService.setLoading = false;
        if (Util.getDevMode()) {
          if (error.status === 403) {
            this.router.navigate(['/' + Cons.TOP]);
          }
        }
        this.dialogService.setDialogInfo = Util.getDialogInfoError(error);
      }
    );
  };

  //各種ボタンが押されたときに各ドロップダウンのクライアント名を選択された値に変更
  setDropDownSelectClientName = (): void => {
    if (this.selectClientName && this.selectClientName !== '') {
      for (let i = 0; i < this.clientNameList.length; i++) {
        if (this.clientNameList[i] === this.selectClientName) {
          this.userService.setUserRegisterClientName = this.clientNameList[i];
          this.clientService.setClientDeleteName = this.clientNameList[i];
          this.userService.setUserDeleteClientName = this.clientNameList[i];
          this.userService.setUserSearchClientName = this.clientNameList[i];
        }
      }
    } else {
      this.setFirstClientName();
    }
  };

  setFirstClientName = () => {
    this.userService.setUserRegisterClientName = this.clientNameList[0];
    this.clientService.setClientDeleteName = this.clientNameList[0];
    this.userService.setUserDeleteClientName = this.clientNameList[0];
    this.userService.setUserSearchClientName = this.clientNameList[0];
  };

  //名前のリストとIDとのマップを作成する。
  createClientInfoList = (info: { clientId: string; clientName: string }[]) => {
    //manegerListを初期化
    this.clientNameList = new Array();
    this.adminCommonService.setClientIdMap = new Map();
    for (let i = 0; i < info.length; i++) {
      this.clientNameList.push(info[i].clientName);
      this.adminCommonService.getClientIdMap.set(
        info[i].clientName,
        info[i].clientId
      );
    }
  };

  onClickDropDownItem = (selectName: string, replaceVal: string) => {
    //replaceVal = selectName;
    switch (replaceVal) {
      case 'clientDeleteName':
        this.clientService.setClientDeleteName = selectName;
        break;
      case 'userRegisterClientName':
        this.userService.setUserRegisterClientName = selectName;
        break;
      case 'userDeleteClientName':
        this.userService.setUserDeleteClientName = selectName;
        break;
      case 'designatedMonth':
        this.logService.setDesignatedMonth = selectName;
        break;
      default:
    }
  };

  //スクロール位置調整
  setScrollHeight = (clientName: string) => {
    let scroll = document.getElementById(clientName);
    if (scroll) {
      scroll.scrollIntoView();
    }
  };

  //名前のリストとIDとのマップを作成する。
  createVendorInfoList = (info: { clientId: string; clientName: string }[]) => {
    return new Promise((resolve) => {
      //manegerListを初期化
      this.vendorNameList = new Array();
      this.adminCommonService.setVendorIdMap = new Map();
      for (let i = 0; i < info.length; i++) {
        this.vendorNameList.push(info[i].clientName);
        this.adminCommonService.getVendorIdMap.set(
          info[i].clientName,
          info[i].clientId
        );
      }
      resolve('処理完了');
    });
  };

  //子テナント名のリスト取得のURL設定
  setVendorNameListUrl = (): Observable<{
    clientInfo: { clientId: string; clientName: string }[];
  }> => {
    let response: Observable<{
      clientInfo: { clientId: string; clientName: string }[];
    }>;
    if (Util.getDevMode()) {
      response = this.accessorService.get(environment.getClientName);
    } else {
      //アップロードするPreSignedUrlを取得する
      response = this.accessorService.get(Util.clientInfoUrl());
    }
    return response;
  };

  updateAllVendorName = (selectedVendorName: string) => {
    this.vendorService.setVendorDeleteName = selectedVendorName;
    this.vendorService.setVendorAdminSearchName = selectedVendorName;
    this.vendorService.setVendorAdminDeleteName = selectedVendorName;
    this.vendorService.setVendorAdminRegisterName = selectedVendorName;
    this.clientService.setClientRegisterVendorName = selectedVendorName;
    this.clientService.setClientDeleteVendorName = selectedVendorName;
    this.userService.setUserSearchVendorName = selectedVendorName;
    this.userService.setUserRegisterVendorName = selectedVendorName;
    this.userService.setUserDeleteVendorName = selectedVendorName;
  };

  setFirstVendorName = () => {
    this.vendorService.setVendorDeleteName = this.vendorNameList[0];
    this.vendorService.setVendorAdminSearchName = this.vendorNameList[0];
    this.vendorService.setVendorAdminDeleteName = this.vendorNameList[0];
    this.vendorService.setVendorAdminRegisterName = this.vendorNameList[0];
    this.clientService.setClientRegisterVendorName = this.vendorNameList[0];
    this.clientService.setClientDeleteVendorName = this.vendorNameList[0];
    this.userService.setUserSearchVendorName = this.vendorNameList[0];
    this.userService.setUserRegisterVendorName = this.vendorNameList[0];
    this.userService.setUserDeleteVendorName = this.vendorNameList[0];
  };

  //クライアント名のリストを取得し、設定する。
  getVendorNameListService = (
    initializeFlg: boolean = false,
    selectedVendorName: string = ''
  ): void => {
    //initializeFlgは画面を開いたときにadminからのみtrueで呼ばれる初回の呼び出し
    this.loadingService.setLoading = true;
    let response = this.setVendorNameListUrl();
    response.subscribe(
      // 通信成功時
      (data) => {
        //sessionのチェック
        let dialogInfo = Util.getSessionErrorDialog(data);
        if (dialogInfo.body) {
          if (initializeFlg) {
            this.router.navigate(['/' + Cons.TOP]);
          } else {
            this.dialogService.setDialogInfo = dialogInfo;
            return;
          }
        }
        (async () => {
          //変数を設定
          await this.createVendorInfoList(data['clientInfo']);
          this.vendorNameList.sort((x, y) => x.localeCompare(y, 'ja'));
          if (this.vendorNameList.length > 0) {
            //client一覧取得
            if (selectedVendorName == '') {
              this.getClientNameListService(this.vendorNameList[0]);
            } else {
              this.getClientNameListService(selectedVendorName);
              this.updateAllVendorName(selectedVendorName);
            }
          }
          this.loadingService.setLoading = false;
          //initializeFlgは画面を開いたときにadminからのみtrueで呼ばれる初回の呼び出し
          if (initializeFlg) {
            this.initializeService.setInitializeCompleteFlg = true;
            this.setFirstVendorName();

            (async () => {
              const fileItem = await this.initializeService.setTenantName();
              TsLogger().log('初期処理テスト中');
              TsLogger().log(fileItem);
              TsLogger().log(this.initializeService.tenantName);
            })();
          }
          //クライアント登録時の処理クライアント名が残っている場合セレクトクライアント名に設定してNULLにする
          if (this.vendorService.getVendorRegisterName) {
            this.setSelectVendorName = this.vendorService.getVendorRegisterName;
            this.vendorService.setVendorRegisterName = '';
          }
        })();
      },
      // 通信失敗時
      (error) => {
        if (error.status == 0) {
          this.dialogService.setDialogInfo = {
            body: 'errUploadTimeout',
            footer: false,
            next: '',
          };
          return;
        }
        this.loadingService.setLoading = false;
        if (Util.getDevMode()) {
          if (error.status === 403) {
            this.router.navigate(['/' + Cons.TOP]);
          }
        }
        if (initializeFlg && error.status === 403) {
          this.loadingService.setLoading = true;
          this.router.navigate(['/' + Cons.TOP]);
          return;
        }
        this.dialogService.setDialogInfo = Util.getDialogInfoError(error);
      }
    );
  };

  get getRegistUserforVendorFlg(): boolean {
    return this.registUserforVendorFlg;
  }
  set setRegistUserforVendorFlg(flg: boolean) {
    this.registUserforVendorFlg = flg;
  }
  get getRegistUserFlg(): boolean {
    return this.registUserFlg;
  }
  set setRegistUserFlg(flg: boolean) {
    this.registUserFlg = flg;
  }
  get getRegistClientFlg(): boolean {
    return this.registClientFlg;
  }
  set setRegistClientFlg(flg: boolean) {
    this.registClientFlg = flg;
  }
  get getDeleteClientforVendorFlg(): boolean {
    return this.deleteClientforVendorFlg;
  }
  set setDeleteClientforVendorFlg(flg: boolean) {
    this.deleteClientforVendorFlg = flg;
  }
  get getDeleteClientFlg(): boolean {
    return this.deleteClientFlg;
  }
  set setDeleteClientFlg(flg: boolean) {
    this.deleteClientFlg = flg;
  }
  get getDeleteUserforVendorFlg(): boolean {
    return this.deleteUserforVendorFlg;
  }
  set setDeleteUserforVendorFlg(flg: boolean) {
    this.deleteUserforVendorFlg = flg;
  }
  get getDeleteUserFlg(): boolean {
    return this.deleteUserFlg;
  }
  set setDeleteUserFlg(flg: boolean) {
    this.deleteUserFlg = flg;
  }
  get getDesignatedFlg(): boolean {
    return this.designatedFlg;
  }
  set setDesignatedFlg(flg: boolean) {
    this.designatedFlg = flg;
  }
  get getSearchUserforVendorFlg(): boolean {
    return this.searchUserforVendorFlg;
  }
  set setSearchUserforVendorFlg(flg: boolean) {
    this.searchUserforVendorFlg = flg;
  }
  get getSearchUserFlg(): boolean {
    return this.searchUserFlg;
  }
  set setSearchUserFlg(flg: boolean) {
    this.searchUserFlg = flg;
  }
  get getDesignatedMonthList(): Array<string> {
    return this.designatedMonthList;
  }
  set setDesignatedMonthList(list: Array<string>) {
    this.designatedMonthList = list;
  }
  get getClientNameList(): Array<string> {
    return this.clientNameList;
  }
  set setClientNameList(list: Array<string>) {
    this.clientNameList = list;
  }
  get getSelectClientName(): string {
    return this.selectClientName;
  }
  set setSelectClientName(name: string) {
    this.selectClientName = name;
    //値を設定された場合にドロップダウンの中身を変更する。
    this.setDropDownSelectClientName();
  }
  get getVendorNameList() {
    return this.vendorNameList;
  }
  set setVendorNameList(list: Array<string>) {
    this.vendorNameList = list;
  }
  get getSelectVendorName(): string {
    return this.selectVendorName;
  }
  set setSelectVendorName(name: string) {
    this.selectVendorName = name;
  }
  get getDeleteVendorFlg() {
    return this.deleteVendorFlg;
  }
  set setDeleteVendorFlg(bool: boolean) {
    this.deleteVendorFlg = bool;
  }
  get getRegistVendorAdminFlg() {
    return this.registVendorAdminFlg;
  }
  set setRegistVendorAdminFlg(bool: boolean) {
    this.registVendorAdminFlg = bool;
  }
  get getDeleteVendorAdminFlg() {
    return this.deleteVendorAdminFlg;
  }
  set setDeleteVendorAdminFlg(bool: boolean) {
    this.deleteVendorAdminFlg = bool;
  }
  get getSearchVendorAdminFlg() {
    return this.searchVendorAdminFlg;
  }
  set setSearchVendorAdminFlg(bool: boolean) {
    this.searchVendorAdminFlg = bool;
  }
}
