// -*- coding: utf-8 -*-
/*"""
Created on 2017/12/06

エラーコード用定数クラス…
エラーコード追加の場合
errorcode.jsonを更新すること
@author: CITS
"""
*/

// サーバー内部エラー
export const INTERNAL_SERVER_ERROR = 1001;

// サーバー内部エラー（ユーザ追加削除時に返却される）
export const INVALID_FILEFUNC = 1002;

// サーバー内部エラー
export const PRINTER_NOT_EXIST = 1003;

// サーバー内部エラー
export const INVALID_PRINTERID = 1004;

// サーバー内部エラー
export const INVALID_APPID = 1005;

// サーバー内部エラー
export const NOT_SUPPORTED_COUNTRY_OR_REGION = 1006;

// サーバー内部エラー
export const JOB_NOT_EXIST = 1007;

// サーバー内部エラー
export const COUNTRY_OR_REGION_MISMATCH = 1008;

// サーバー内部エラー
export const BAD_REQUEST = 4000;

// 初回アクセスコード
export const FIRST_ACCESS = 4100;
