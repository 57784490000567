import { Injectable } from '@angular/core';
import { DialogInfo } from '../model/dialog-info';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  // エラーダイアログ用オブジェクト
  private dialogInfo: DialogInfo = new DialogInfo();
  // コールバック系
  private callbackDialog: Function = () => {};
  private callbackDialogNG: Function = () => {};

  constructor() {}

  get getDialogInfo(): DialogInfo {
    return this.dialogInfo;
  }
  set setDialogInfo(dialogInfo: DialogInfo) {
    this.dialogInfo = dialogInfo;
  }
  get getCallbackDialog(): Function {
    return this.callbackDialog;
  }
  set setCallbackDialog(dialog: Function) {
    if (dialog) {
      this.callbackDialog = dialog;
    } else {
      this.callbackDialog = () => {};
      this.callbackDialogNG = () => {};
    }
  }
  get getCallbackDialogNG(): Function {
    return this.callbackDialogNG;
  }
  set setCallbackDialogNG(dialog: Function) {
    if (dialog) {
      this.callbackDialogNG = dialog;
    } else {
      this.callbackDialog = () => {};
      this.callbackDialogNG = () => {};
    }
  }
}
