import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import * as Cons from './appcommon/constants/constants';

import { ErrorComponent } from './error/error.component';
import { AdminComponent } from './admin/admin.component';
import { SettingsComponent } from './settings/settings.component';
import { TopComponent } from './top/top.component';
import { NotFoundUserComponent } from './not-found-user/not-found-user.component';
import { PrintLogGridComponent } from './container/print-log-grid/print-log-grid.component';
import { TopGuard } from '../app/guard-container/top.guard';
import { SoftwareLicenseComponent } from './software-license/software-license.component';

const routes: Routes = [
  { path: Cons.ERROR, component: ErrorComponent },
  { path: Cons.TOP, component: TopComponent },
  { path: Cons.NOTFOUNDUSER, component: NotFoundUserComponent },
  { path: Cons.SOFTLICENSE, component: SoftwareLicenseComponent },
  {
    path: '',
    children: [
      { path: Cons.ADMIN, component: AdminComponent },
      { path: Cons.SETTINGS, component: SettingsComponent },
      { path: '**', redirectTo: `/${Cons.ADMIN}`, pathMatch: 'full' },
    ],
    canActivate: [TopGuard],
  },
  { path: '**', redirectTo: `/${Cons.TOP}`, pathMatch: 'full' },
];

//PrintLogGridComponent

// ルート設定
const routeConfig: ExtraOptions = {
  enableTracing: false,
  useHash: true,
  initialNavigation: true,
  scrollPositionRestoration: 'top',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routeConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
