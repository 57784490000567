import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';

import { TsLogger } from '../log/log';
import { Util } from '../util/util';
import { AccessorService } from './accessor.service';
import { AdminCommonService } from './admin-common.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  //クライアント登録用のベンダー名
  private clientRegisterVendorName: string = '';
  //クライアント登録用のクライアント名
  private clientRegisterName: string = '';
  //クライアント削除用テナント
  private clientDeleteVendorName: string = '';
  //クライアント削除用のクライアント名
  private clientDeleteName: string = '';

  constructor(
    private accessorService: AccessorService,
    private adminCommonService: AdminCommonService
  ) {}

  /** クライアント削除処理 **/
  deleteCientInfoObservable = (): Observable<{ success: boolean }> => {
    // とりあえず同じURLを呼ぶものだけでも共通化する。
    let response: Observable<{ success: boolean }>;
    if (Util.getDevMode()) {
      const url = environment.deleteSession;
      response = this.accessorService.get(url);
      TsLogger().log(
        this.adminCommonService.getClientIdMap.get(this.clientDeleteName)
      );
    } else {
      response = this.accessorService.delete(
        Util.deleteTenantClientInfoUrl(
          this.adminCommonService.getVendorIdMap.get(
            this.clientDeleteVendorName
          ),
          this.adminCommonService.getClientIdMap.get(this.clientDeleteName)
        )
      );
    }
    return response;
  };

  /** クライアント登録処理 **/
  postCientInfoObservable = (
    vendorName: string
  ): Observable<{ success: boolean }> => {
    // とりあえず同じURLを呼ぶものだけでも共通化する。
    let response: Observable<{ success: boolean }>;
    if (Util.getDevMode()) {
      const url = environment.deleteSession;
      response = this.accessorService.get(url);
    } else {
      response = this.accessorService.post(
        Util.getTenantClientInfoUrl(
          this.adminCommonService.getVendorIdMap.get(vendorName)
        ),
        JSON.stringify({
          clientName: this.clientRegisterName,
        })
      );
    }
    return response;
  };

  get getClientRegisterVendorName(): string {
    return this.clientRegisterVendorName;
  }
  set setClientRegisterVendorName(name: string) {
    this.clientRegisterVendorName = name;
  }
  get getClientRegisterName(): string {
    return this.clientRegisterName;
  }
  set setClientRegisterName(clientName: string) {
    this.clientRegisterName = clientName;
  }
  get getClientDeleteVendorName(): string {
    return this.clientDeleteVendorName;
  }
  set setClientDeleteVendorName(vendorName: string) {
    this.clientDeleteVendorName = vendorName;
  }
  get getClientDeleteName(): string {
    return this.clientDeleteName;
  }
  set setClientDeleteName(clientName: string) {
    this.clientDeleteName = clientName;
  }
}
