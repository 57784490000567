import { Observable, timer } from 'rxjs';
import { delayWhen, retryWhen, take, tap } from 'rxjs/operators';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CookieService } from '../../../../node_modules/ngx-cookie-service';
import * as Cons from '../constants/constants';
import { TsLogger } from '../log/log';
import { AdminCommonService } from '../service/admin-common.service';
import { Util } from '../util/util';

@Injectable()
export class Appinterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private adminCommonService: AdminCommonService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const req = request.clone({
      //csrf/xsrf対策としてヘッダーに取得したトークンを詰める。
      headers: request.headers.set(
        'X-XSRF-TOKEN',
        this.adminCommonService.getXsrfToken
      ),
    });
    TsLogger().log('request url = ' + req.url);
    var count = 1;

    return next.handle(req).pipe(
      //retry の定義
      retryWhen((errors) =>
        errors.pipe(
          delayWhen((error) => {
            if (error instanceof HttpErrorResponse) {
              TsLogger().error('error.status = ' + error.status);
              TsLogger().log('count =  ' + count);
              //takeの終わらせ方があいまいなのでリトライ4回目はせずに終了
              if (count >= 8) {
                throw error;
              }

              TsLogger().error('error.error = ' + error.error);
              if (error.status <= 499 && 400 <= error.status) {
                if (error.status == 401) {
                  TsLogger().log('event end or session invalid');
                  //session error or event endのため、エラーとする
                  this.cookieService.delete(
                    Cons.SESSION_ID,
                    Util.getCookiePath()
                  );
                }

                TsLogger().error('no retry :4xx');
                //エラーレスポンスをそのまま返す
                throw error;
              }
              switch (error.status) {
                // 通信エラー
                case 500:
                case 504:
                  TsLogger().error('no retry : 500 or 504');
                  throw error;
                default:
                  break;
              }
            }
            let returnTimer = timer(count * 1000);
            count = count * 2;
            return returnTimer;
          }),
          take(5)
        )
      ),
      tap((event) => {
        if (event instanceof HttpResponse) {
          TsLogger().log('success =  ' + event.status);
          TsLogger().log('body =  ' + JSON.stringify(event.body));
        }
      })
    );
  }
}
