import { Component, OnInit } from '@angular/core';
import { WordInfoService } from '../../appcommon/service/word-info.service';
import * as Cons from '../../appcommon/constants/constants';
import { LoadingService } from '../../appcommon/service/loading.service';
import { DialogService } from '../../appcommon/service/dialog.service';
import {LogOffService} from '../../appcommon/service/log-off.service';
import { Util } from '../../appcommon/util/util';
import { Router } from '@angular/router';
@Component({
  selector: 'app-settings-container',
  templateUrl: './settings-container.component.html',
  styleUrls: ['./settings-container.component.scss']
})
export class SettingsContainerComponent implements OnInit {

  constructor(
    private router:Router,
    public wordInfoService: WordInfoService,
    public dialogService:DialogService,
    public logOffService: LogOffService,
    private loadingService:LoadingService
  ) { }

  ngOnInit(): void {
    this.loadingService.setLoading = false;
  }

  onClickLogOut(): void {
    this.loadingService.setLoading = true;

    this.dialogService.setDialogInfo = {
      body: 'unregistMsg',
      footer: true,
      next: '',
    };
    //ダイアログの閉じるクリック後の挙動をLambda式で
    this.dialogService.setCallbackDialog = () => {
      //セッションを削除
      this.logOffService.deleteUser();
    };
    this.dialogService.setCallbackDialogNG = () => {
      this.loadingService.setLoading = false;
    };
    return;
  }

  onClickCanonIdSetting(): void {
    let url = '';
    url = Util.getCanonIdUrl();
    window.open(url, 'AAA PRINT');
  }

  onClose(): void {
    this.transition_pages(Cons.MAIN);
  }

  // 移動
  transition_pages(nextPage: string) {
    this.router.navigate(['/' + nextPage]);
  }

}
