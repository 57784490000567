<div class="layout">
    <app-side-menu [nowSetting]="true"></app-side-menu>
    <div class="layout_main">
        <app-header [firstTime]="false"></app-header>
        <div class="outer">
            <div class="inner-minwidth-1000">
                <app-settings-container></app-settings-container>
            </div>
        </div>
    </div>
</div>