import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { httpInterceptorProviders } from './intercept/interceptor-index';
import { ToZenkakuPipe } from './pipe/to-zenkaku.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [ToZenkakuPipe],
  providers: [httpInterceptorProviders],
  exports: [ToZenkakuPipe],
})
export class AppcommonModule {}
