import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';

import * as Cons from '../../appcommon/constants/constants';
import { TsLogger } from '../log/log';
import { Util } from '../util/util';
import { AccessorService } from './accessor.service';
import { AdminCommonService } from './admin-common.service';
import { DialogService } from './dialog.service';
import { LoadingService } from './loading.service';
import { WordInfoService } from './word-info.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  //ユーザ追加用のベンダー名
  private userRegisterVendorName: string = '';
  //ユーザ追加用のクライアント名
  private userRegisterClientName: string = '';
  //ユーザ追加用ファイル格納
  private userRegistCsvFile: Array<string> = new Array();
  //ユーザ追加用ファイル名
  private userRegistCsvFileName: string = '';
  //ユーザの登録成功回数
  private userRegisterSuccessNum: number = 0;
  //検索用ベンダー名
  private userSearchVendorName: string = '';
  //検索用クライアント名
  private userSearchClientName: string = '';
  //ユーザ削除用のベンダー名
  private userDeleteVendorName: string = '';
  //ユーザ削除用のクライアント名
  private userDeleteClientName: string = '';
  //ユーザ削除用ファイル格納
  private userDeleteCsvFile: Array<string> = new Array();
  //ユーザ削除用ファイル名
  private userDeleteCsvFileName: string = '';
  //delete処理繰返し制御用変数
  private deletePollingNum: number = 0;
  //ユーザの削除成功回数
  private userDeleteSuccessNum: number = 0;
  //検索用のユーザデータ格納用
  private userInfo: Array<string> = new Array();
  //ユーザ追加時に追加出来なかったデータを格納する。
  private errorListMap: Map<string, Array<string>> = new Map();
  //IE用のインターバル止める用ためのID
  private timerId: number = 0;
  //ユーザデータ追加用インターバルを止めるためのID
  private postUserTimerId: number = 0;
  //ユーザデータ追加が全て処理が完了したかを制御するための変数
  private postUserCompFlg: Array<boolean> = new Array();
  //ユーザデータ追加時の分割個数
  private postUserFileSplitNum: number = 1;
  //ユーザデータ削除用インターバルを止めるためのID
  private deleteUserTimerId: number = 0;
  //ユーザデータ削除の処理が全て完了したかを制御するための変数
  private deleteUserCompFlg: Array<boolean> = new Array();
  //ユーザデータ削除時の分割個数
  private deleteUserFileSplitNum: number = 1;

  constructor(
    private accessorService: AccessorService,
    private loadingService: LoadingService,
    private wordInfoService: WordInfoService,
    private adminCommonService: AdminCommonService,
    private dialogService: DialogService
  ) {}

  //user情報を取得する。
  getUserInfoService = (
    tenantName: string,
    clientName: string
  ): Observable<{ success: boolean; userInfo: Array<string> }> => {
    let response: Observable<{ success: boolean; userInfo: Array<string> }>;
    if (Util.getDevMode()) {
      if (environment.noUserInfo) {
        //ユーザの削除完了
        response = this.accessorService.get(environment.getNoUserInfo);
      } else {
        //ユーザの削除未完了
        response = this.accessorService.get(environment.getUserInfo);
      }
    } else {
      TsLogger().log('userInfo取得');
      //アップロードするuserInfoを取得する
      response = this.accessorService.get(
        Util.tenantUserInfoUrl(
          this.adminCommonService.getVendorIdMap.get(tenantName),
          this.adminCommonService.getClientIdMap.get(clientName)
        )
      );
    }
    return response;
  };
  //userのメールアドレスを取得する
  getUserMailAdress = (
    hashedMailAdress: string,
    clientId: string | undefined,
    tenantId: string | undefined
  ): Observable<{ success: boolean; userInfo: string }> => {
    let response: Observable<{ success: boolean; userInfo: string }>;
    if (Util.getDevMode()) {
      TsLogger().log(hashedMailAdress);
      response = this.accessorService.get(environment.getUserMailAdress);
    } else {
      TsLogger().log('userMailAdress取得');
      //アップロードするuserInfoを取得する
      response = this.accessorService.get(
        Util.tenantUserMailAdressRequestUrl(
          tenantId,
          clientId,
          hashedMailAdress
        )
      );
    }
    return response;
  };
  //user情報を登録する。
  postUserInfoService = (
    mailAdress: string
  ): Observable<{ success: boolean; error: string }> => {
    // とりあえず同じURLを呼ぶものだけでも共通化する。
    let response: Observable<{ success: boolean; error: string }>;
    if (Util.getDevMode()) {
      TsLogger().log(mailAdress);
      let random = Math.ceil(Math.random() * 100);
      if (random <= 20) {
        response = this.accessorService.get(environment.getPostUserResults);
      } else if (random > 20 && random <= 40) {
        response = this.accessorService.get(environment.noClientId);
      } else if (random > 40 && random <= 60) {
        response = this.accessorService.get(environment.existUser);
      } else {
        response = this.accessorService.get(environment.serverError);
      }
    } else {
      response = this.accessorService.post(
        Util.tenantUserInfoUrl(
          this.adminCommonService.getVendorIdMap.get(
            this.userRegisterVendorName
          ),
          this.adminCommonService.getClientIdMap.get(
            this.userRegisterClientName
          )
        ),
        JSON.stringify({
          userInfo: mailAdress,
        })
      );
    }
    return response;
  };

  //新規登録・削除用のCSVファイルを配列に格納する
  setUserPostDeleteCsvArray = (item: string, funcName: string) => {
    let fileArray = item.split('\n');
    let csvFileItem = fileArray.filter((v: string) => v);
    let arrayTmp = new Array();
    //CSVの途中で空が入っている。
    //その空文字は何故か1文字の情報を持った空文字になるためそれを弾く
    for (let i = 0; i < csvFileItem.length; i++) {
      if (csvFileItem[i].length !== 1) {
        arrayTmp.push(
          csvFileItem[i].replace('\r', '').replace(/[\s]/g, '').trim()
        );
      }
    }
    csvFileItem = arrayTmp;
    //csvが1000件より多い場合エラー
    if (csvFileItem.length > Cons.FILELISTLIMIT) {
      this.dialogService.setDialogInfo = {
        body: 'errorFileLimit',
        footer: false,
        next: '',
      };
      //ダイアログの閉じるクリック後の挙動をLambda式で
      this.dialogService.setCallbackDialog = () => {
        //はいが押下されたとき
        this.loadingService.setLoading = false;
        this.clearCsvFileItem(funcName);
      };
      return;
    }
    //csvが一件もない場合はエラーとする。
    if (csvFileItem.length === 0) {
      //errorFileEmpty
      this.dialogService.setDialogInfo = {
        body: 'errorFileEmpty',
        footer: false,
        next: '',
      };
      //ダイアログの閉じるクリック後の挙動をLambda式で
      this.dialogService.setCallbackDialog = () => {
        //はいが押下されたとき
        this.loadingService.setLoading = false;
        this.clearCsvFileItem(funcName);
      };
      return;
    }
    //メールアドレスチェックをする。
    let errorMailAdress = this.adminCommonService.checkMailAdress(csvFileItem);
    if (errorMailAdress !== '') {
      let msg = this.wordInfoService
        .getWord('errorMailAdress')
        .replace('{0}', errorMailAdress);
      this.dialogService.setDialogInfo = {
        body: msg,
        footer: false,
        next: '',
        replaceFlg: true,
      };
      //ダイアログの閉じるクリック後の挙動をLambda式で
      this.dialogService.setCallbackDialog = () => {
        //はいが押下されたとき
        this.loadingService.setLoading = false;
        this.clearCsvFileItem(funcName);
      };
    } else {
      this.loadingService.setLoading = false;
      this.setCsvFileItem(csvFileItem, funcName);
    }
  };

  setCsvFileItem = (csvItem: Array<string>, funcName: string) => {
    if (funcName === Cons.POST) {
      this.userRegistCsvFile = csvItem;
    } else {
      this.userDeleteCsvFile = csvItem;
    }
  };

  clearCsvFileItem = (funcName: string) => {
    if (funcName === Cons.POST) {
      this.userRegistCsvFile = new Array();
      this.userRegistCsvFileName = '';
    } else {
      this.userDeleteCsvFile = new Array();
      this.userDeleteCsvFileName = '';
    }
  };

  getDeleteUserInfoUrl = (
    hash: string
  ): Observable<{ success: boolean; error: string }> => {
    let response: Observable<{ success: boolean; error: string }>;
    if (Util.getDevMode()) {
      let random = Math.ceil(Math.random() * 100);
      if (random <= 20) {
        response = this.accessorService.get(environment.getDeleteUserResults);
      } else if (random > 20 && random <= 40) {
        response = this.accessorService.get(environment.notExistUser);
      } else if (random > 40 && random <= 60) {
        response = this.accessorService.get(environment.otherClient);
      } else {
        response = this.accessorService.get(environment.serverError);
      }
    } else {
      response = this.accessorService.delete(
        Util.tenantUserMailAdressRequestUrl(
          this.adminCommonService.getVendorIdMap.get(this.userDeleteVendorName),
          this.adminCommonService.getClientIdMap.get(this.userDeleteClientName),
          hash
        )
      );
    }
    return response;
  };

  //追加処理が完了した場合に値を格納するための関数
  changePostUserCompFlg = (result: boolean) => {
    this.postUserCompFlg.push(result);
  };

  //追加・削除処理の完了フラグが全てtrueかを確認する
  checkCompFlg = (list: Array<boolean>): boolean => {
    for (let i = 0; i < list.length; i++) {
      if (!list[i]) {
        return false;
      }
    }
    return true;
  };

  //エラー時のメッセージを生成して返却する。
  createErrorMessage = (
    errorNameList: Array<string>,
    errorNameToMessage: Map<string, string>,
    msg: string
  ): string => {
    let errorMailAdressNum = 0;
    let serverErrorMessage = '';
    for (let i = 0; i < errorNameList.length; i++) {
      if (this.errorListMap.get(errorNameList[i])) {
        let mailAdressList: Array<string> = this.errorListMap.get(
          errorNameList[i]
        ) as Array<string>;
        //サーバーエラー以外は件数を表示する
        if (errorNameList[i] !== 'serverError') {
          errorMailAdressNum += mailAdressList.length;
        } else {
          let messageAdress = '';
          for (let j = 0; j < mailAdressList.length; j++) {
            messageAdress += mailAdressList[j] + '<br>';
          }
          if (
            mailAdressList.length > 0 &&
            errorNameToMessage.get(errorNameList[i])
          ) {
            let nameList: string = errorNameToMessage.get(
              errorNameList[i]
            ) as string;
            serverErrorMessage += this.wordInfoService
              .getWord(nameList)
              .replace('{0}', messageAdress);
          }
        }
      }
    }
    if (errorMailAdressNum !== 0) {
      msg += this.wordInfoService
        .getWord('errorPostDeleteNum')
        .replace('{0}', errorMailAdressNum);
    }
    if (serverErrorMessage !== '') {
      msg += serverErrorMessage;
    }
    return msg;
  };

  //メールアドレスをエラーリストに追加する。
  addMailAdressToErrorMap = (
    errorName: string,
    mailAdress: string,
    list: Array<string>
  ) => {
    let errorNameList = list;
    for (let i = 0; i < errorNameList.length; i++) {
      if (errorNameList[i] === errorName) {
        if (this.errorListMap.get(errorName)) {
          let errormailAdress: Array<string> = this.errorListMap.get(
            errorName
          ) as Array<string>;
          errormailAdress.push(mailAdress);
          this.errorListMap.set(errorName, errormailAdress);
        }
      }
    }
  };

  //マップの存在チェック
  //リストに値が入っているかを確認する
  checkMapExistMailAdressList = (list: Array<string>): boolean => {
    let errorNameList = list;
    for (let i = 0; i < errorNameList.length; i++) {
      if (
        this.errorListMap.get(errorNameList[i]) &&
        (this.errorListMap.get(errorNameList[i]) as Array<string>).length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  //ユーザ削除時に削除成功した場合userDeleteSuccessNumを+1する
  countUpUserDeleteSuccessNum = (): void => {
    this.userDeleteSuccessNum += 1;
  };

  //ユーザ削除時に処理が成功したか否かのフラグdeleteUserCompFlgを更新する
  updateDeleteUserCompFlg = (flg: boolean): void => {
    this.deleteUserCompFlg.push(flg);
  };

  //ユーザ削除時のエラーリストを初期化する。
  deleteUserInitmapList = () => {
    for (let i = 0; i < Cons.DELETEUSERMAP.length; i++) {
      this.initErrorMap(Cons.DELETEUSERMAP[i]);
    }
  };

  //ユーザ追加時のエラーリストを初期化する。
  postUserInitmapList = () => {
    for (let i = 0; i < Cons.POSTUSERERRORMAP.length; i++) {
      this.initErrorMap(Cons.POSTUSERERRORMAP[i]);
    }
  };

  //CSVファイル処理ユーザの追加・削除のエラーを格納するためのMAP初期化
  initErrorMap = (mapName: string) => {
    this.errorListMap.set(mapName, new Array<string>());
  };

  //ユーザ情報の配列を追加するメソッド
  addUserInfo = (list: Array<string>) => {
    this.userInfo.concat(list);
  };

  get getUserRegisterVendorName(): string {
    return this.userRegisterVendorName;
  }
  set setUserRegisterVendorName(VendorName: string) {
    this.userRegisterVendorName = VendorName;
  }
  get getUserRegisterClientName(): string {
    return this.userRegisterClientName;
  }
  set setUserRegisterClientName(clientName: string) {
    this.userRegisterClientName = clientName;
  }
  get getUserRegistCsvFile() {
    return this.userRegistCsvFile;
  }
  set setUserRegistCsvFile(file: Array<string>) {
    if (!file) {
      this.userRegistCsvFileName = '';
    }
    this.userRegistCsvFile = file;
  }
  get getUserRegistCsvFileName(): string {
    return this.userRegistCsvFileName;
  }
  set setUserRegistCsvFileName(name: string) {
    this.userRegistCsvFileName = name;
  }

  get getUserRegisterSuccessNum(): number {
    return this.userRegisterSuccessNum;
  }
  set setUserRegisterSuccessNum(num: number) {
    this.userRegisterSuccessNum = num;
  }
  get getUserSearchVendorName(): string {
    return this.userSearchVendorName;
  }
  set setUserSearchVendorName(vendorName: string) {
    this.userSearchVendorName = vendorName;
  }
  get getUserSearchClientName(): string {
    return this.userSearchClientName;
  }
  set setUserSearchClientName(clientName: string) {
    this.userSearchClientName = clientName;
  }
  get getUserDeleteVendorName() {
    return this.userDeleteVendorName;
  }
  set setUserDeleteVendorName(vendorName: string) {
    this.userDeleteVendorName = vendorName;
  }
  get getUserDeleteClientName() {
    return this.userDeleteClientName;
  }
  set setUserDeleteClientName(clientName: string) {
    this.userDeleteClientName = clientName;
  }
  get getUserDeleteCsvFileName(): string {
    return this.userDeleteCsvFileName;
  }
  set setUserDeleteCsvFileName(name: string) {
    this.userDeleteCsvFileName = name;
  }
  get getUserDeleteCsvFile() {
    return this.userDeleteCsvFile;
  }
  set setUserDeleteCsvFile(file: Array<string>) {
    if (!file) {
      this.userDeleteCsvFileName = '';
    }
    this.userDeleteCsvFile = file;
  }
  get getDeletePollingNum(): number {
    return this.deletePollingNum;
  }
  set setDeletePollingNum(num: number) {
    this.deletePollingNum = num;
  }
  get getUserInfo(): Array<string> {
    return this.userInfo;
  }
  set setUserInfo(info: Array<string>) {
    this.userInfo = info;
  }
  get getTimerId(): number {
    return this.timerId;
  }
  set setTimerId(id: number) {
    this.timerId = id;
  }
  get getUserDeleteSuccessNum(): number {
    return this.userDeleteSuccessNum;
  }
  set setUserDeleteSuccessNum(num: number) {
    this.userDeleteSuccessNum = num;
  }
  get getPostUserTimerId() {
    return this.postUserTimerId;
  }
  set setPostUserTimerId(id: number) {
    this.postUserTimerId = id;
  }
  get getPostUserCompFlg(): Array<boolean> {
    return this.postUserCompFlg;
  }
  set setPostUserCompFlg(list: Array<boolean>) {
    this.postUserCompFlg = list;
  }
  get getPostUserFileSplitNum(): number {
    return this.postUserFileSplitNum;
  }
  set setPostUserFileSplitNum(num: number) {
    this.postUserFileSplitNum = num;
  }
  get getDeleteUserTimerId(): number {
    return this.deleteUserTimerId;
  }
  set setDeleteUserTimerId(id: number) {
    this.deleteUserTimerId = id;
  }
  get getDeleteUserCompFlg(): Array<boolean> {
    return this.deleteUserCompFlg;
  }
  set setDeleteUserCompFlg(list: Array<boolean>) {
    this.deleteUserCompFlg = list;
  }
  get getDeleteUserFileSplitNum(): number {
    return this.deleteUserFileSplitNum;
  }
  set setDeleteUserFileSplitNum(num: number) {
    this.deleteUserFileSplitNum = num;
  }
  get getErrorListMap(): Map<string, Array<string>> {
    return this.errorListMap;
  }
  set setErrorListMap(map: Map<string, Array<string>>) {
    this.errorListMap = map;
  }
}
