import { CookieService } from 'ngx-cookie-service';

import { HttpErrorResponse } from '../../../../node_modules/@angular/common/http';
import { environment } from '../../../environments/environment';
import * as Cons from '../constants/constants';
import * as ErrorCons from '../constants/error-constants';
import { DialogInfo } from '../model/dialog-info';

export class Util {
  static doSomething(val: string) {
    return val;
  }

  static doSomethingElse(val: string) {
    return val;
  }

  static getDevMode() {
    return !environment.production;
  }

  static getlogMode() {
    return environment.debuglog;
  }

  /* 無効なイベントエラーのダイアログ情報を取得する */
  static getDialogInfoInvalidEvent() {
    return {
      body: 'W000036',
      footer: false,
    };
  }

  /* 無効なフレームのダイアログ情報を取得する */
  static getDialogInfoInvalidFrame() {
    return {
      body: 'W000037',
      footer: 'W000022',
    };
  }

  //セッションのチェックを行う(4XXでは無く200で返却するため)
  static checkSession(data: any): boolean {
    if (Cons.SESSIONEXPIRED in data) {
      if (data[Cons.SESSIONEXPIRED]) {
        return true;
      }
    }
    return false;
  }

  static getSessionErrorDialog(data: any): DialogInfo {
    let dialogInfo: DialogInfo = new DialogInfo();
    if (this.checkSession(data)) {
      dialogInfo = {
        body: 'errSessionExpired',
        footer: false,
        next: Util.getLoginUrl(),
      };
    }
    return dialogInfo;
  }

  /* エラー内容に従ったダイアログ情報を取得する
     次バージョン以降WordInfoServiceではなく、routerを与えるように改修予定。
  */
  static getDialogInfoError(error: HttpErrorResponse): DialogInfo {
    let dialogInfo: DialogInfo = new DialogInfo();
    switch (error.status) {
      // 通信エラー
      case 0:
        dialogInfo = {
          body: 'errUnknown',
          footer: false,
          next: '',
        };
        break;
      // サーバ応答エラー
      case 500:
        switch (this.getErrorCode(error)) {
          case ErrorCons.INTERNAL_SERVER_ERROR:
            dialogInfo = {
              body: 'errUnknown',
              footer: false,
              next: '',
            };
            break;
          default:
            dialogInfo = {
              body: 'errUnknown',
              footer: false,
              next: Util.getLoginUrl(),
            };
            break;
        }
        break;
      // TOPに戻らない予期せぬエラー
      case 404:
        switch (this.getErrorCode(error)) {
          case ErrorCons.JOB_NOT_EXIST:
          default:
            dialogInfo = {
              body: 'errUnknown',
              footer: false,
              next: '',
            };
            break;
        }
        break;
      case 403:
        switch (this.getErrorCode(error)) {
          case ErrorCons.FIRST_ACCESS:
            location.href = Util.getLoginUrl();
            break;
          case ErrorCons.COUNTRY_OR_REGION_MISMATCH:
            dialogInfo = {
              body: 'errUnknown',
              footer: false,
              next: Util.getLoginUrl(),
            };
            break;
          default:
            dialogInfo = {
              body: 'errSessionExpired',
              footer: false,
              next: Util.getLoginUrl(),
            };
            break;
        }
        break;
      case 400:
        switch (this.getErrorCode(error)) {
          default:
            dialogInfo = {
              body: 'errorBadRequest',
              footer: false,
              next: Util.getLoginUrl(),
            };
            break;
        }
        break;
      default:
        dialogInfo = {
          body: 'errUnknown',
          footer: false,
          next: Util.getLoginUrl(),
        };
        break;
    }
    return dialogInfo;
  }

  //canonIDの本番URLを取得する。
  static getCanonIdUrl(): string {
    if (
      environment.production &&
      Cons.PRDHOSTNAME === window.location.hostname
    ) {
      return `https://myid.canon/`;
    } else {
      return `https://myid.canon/`;
    }
  }

  //canonIDのクライアントIDを取得する。
  static getCanonIdClientId(): string {
    if (
      environment.production &&
      Cons.PRDHOSTNAME === window.location.hostname
    ) {
      return 'c8dc223b-20ea-4477-9d5f-58a83996f3ec';
    } else {
      return '3778594e-8e0c-4afe-9a47-598878a377b4';
    }
  }

  //canonIDのログアウトURLを取得する
  static getLogoutCanonIdURL(url: string): string {
    return (
      this.getCanonIdUrl() +
      'canonid/account/fe/authn/client-logout?redirect_url=' +
      url
    );
  }

  //loginURLを取得する
  //初期処理を行うURLを取得する。
  static getLoginUrl(): string {
    return `https://${window.location.hostname}/#/top`;
  }

  //初期処理を行うURLを取得する。
  static getInitializeUrl(): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/page/initialize`;
  }

  //ユーザに関するURLを取得する
  static getUserUrl(): string {
    return `https://${window.location.host}/${this.getStg()}/v1/user`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static getRequestUrl(): string {
    return `https://${window.location.host}/${this.getStg()}/v1/user/files`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static getGridUserListRequestUrl(): string {
    return `https://${window.location.host}/${this.getStg()}/v1/user/users`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static logFileUrl(id: string = ''): string {
    if (id) {
      return `https://${
        window.location.host
      }/${this.getStg()}/v1/logFiles/${id}`;
    } else {
      return `https://${window.location.host}/${this.getStg()}/v1/logFiles`;
    }
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static adminInfoRequestUrl(): string {
    return `https://${window.location.host}/${this.getStg()}/v1/admins`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static adminMailAdressRequestUrl(hashedMailAdress: string): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/admins/${hashedMailAdress}`;
  }

  static deleteAdminRequestUrl(hashedMailAdress: string): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/admins/${hashedMailAdress}`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static postTenantRequestUrl(): string {
    return `https://${window.location.host}/${this.getStg()}/v1/tenants`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static tenantUserInfoUrl(
    tenantId: string | undefined,
    clientId: string | undefined
  ): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/tenants/${tenantId}/users/?clientId=${clientId}`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static tenantUserMailAdressRequestUrl(
    tenantId: string | undefined,
    clientId: string | undefined,
    hashedMailAdress: string
  ): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/tenants/${tenantId}/users/${hashedMailAdress}/?clientId=${clientId}`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static getTenantClientInfoUrl(tenantId: string | undefined): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/tenants/${tenantId}/clients`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static deleteTenantClientInfoUrl(
    tenantId: string | undefined,
    clientId: string | undefined
  ): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/tenants/${tenantId}/clients/${clientId}`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static TenantAdminInfoUrl(tenantId: string | undefined): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/tenants/${tenantId}/admins`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static tenantAdminMailAdressRequestUrl(
    tenantId: string | undefined,
    hashedMailAdress: string
  ): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/tenants/${tenantId}/admins/${hashedMailAdress}`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static getSessionUrl(): string {
    return `https://${window.location.host}/${this.getStg()}/v1/page/session`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static clientInfoUrl(): string {
    return `https://${window.location.host}/${this.getStg()}/v1/clients`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static deleteClientInfoUrl(clientId: string | undefined): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/clients/${clientId}`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static getPrintJobUrl(): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/user/jobs/print`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static getFilePutCompRequestUrl(fileid: string): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/user/files/${fileid}`;
  }

  //プリンター登録に関するURLを取得する
  static getPrinters(): string {
    return `https://${window.location.host}/${this.getStg()}/v1/user/printers`;
  }

  //プリンター登録に関するURLを取得する
  static getJobSettings(printerid: string): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/user/printers/${printerid}/jobsettings`;
  }

  /* エンドポイントにアクセスするためのドメインをhttps://付きで取得する */
  static getXsrfTokenUrl(): string {
    return `https://${window.location.host}/${this.getStg()}/v1/page/nonce`;
  }

  //プリントJOB投入用URL
  static postPrintJobUrl(): string {
    return `https://${
      window.location.host
    }/${this.getStg()}/v1/user/jobs/print`;
  }

  static getCookiePath(): string {
    return `/${this.getStg()}/web/v1/public/${this.getEventCodebyEnv()}/`;
  }
  /* イベントコードを取得する */
  static getEventCodebyEnv(): string {
    return this.getEventCode(window.location.pathname);
  }

  /* イベントコードを取得の正式版　pathを与えるとpathから正規表現でeventcode取得する。 */
  static getEventCode(path: string): string {
    var m = path.match(/(.*?)(public\/)(.*?)\//);
    if (m) {
      var eventcode = m[3];
      return eventcode;
    } else {
      return '';
    }
  }

  /* apigatewayのステージ( api or stg)を現在のpathから取得　 */
  static getStg(): string {
    //var path = window.location.pathname;
    //var regex = new RegExp("^\/([^\/]+)\/.*?")
    //var stg = path.replace(regex,"$1")

    //var match = path.match(/^\/(.*?)\/(.*?)\//);
    return 'api';
  }

  /* Stringでキー名を与えれば、storageから削除　*/
  static deleteSessionStorage(deleteKey: string[]): void {
    deleteKey.forEach((element) => {
      window.sessionStorage.removeItem(element);
    });
  }

  /* HttpErrorResponseからbodyを取得する */
  static getErrorBodyJson(error: HttpErrorResponse) {
    var body;
    try {
      body = JSON.parse(error.error);
    } catch (e) {
      //多少遅くなる可能性があるが、エラーケースのためexcept実施。
      //json文字列ではないなら、普通に文字列として返却する
      body = error.error;
    }
    return body;
  }

  /* errorレスポンスからbodyを取り出す */
  static getErrorCode(error: HttpErrorResponse) {
    let body = this.getErrorBodyJson(error);
    if (!this.isObject(body)) {
      //stringまたは、null(またはundefined)が返却される
      return body;
    }

    if (body['code']) {
      //エラーコードがあれば
      return body['code'];
    }
    //object(つまり{}を持つ連想配列ではあるが、[errorCode]のキーがない )
    return 'non error code';
  }

  //言語取得できない場合、一律jaにする
  static getLanguageCode(cookie: CookieService) {
    if (cookie.get(Cons.LANGUAGE_CODE)) {
      return cookie.get(Cons.LANGUAGE_CODE);
    }
    return Cons.LANGUAGE_LIST[0];
  }

  //object判定
  static isObject(obj: unknown) {
    if (obj) {
      return false;
    } else {
      return typeof obj === 'object' ? true : false;
    }
  }
  //配列判定
  static isArray(obj: unknown) {
    if (obj) {
      return false;
    } else {
      return typeof obj === 'object'
        ? Object.getPrototypeOf(obj) === Array.prototype
        : false;
    }
  }

  static printLogCellRenderer = (params: any) => {
    let img =
      '<img src="' +
      params.value +
      '" /><a href="' +
      params.value +
      '" target="_blank" rel="noopener noreferrer">サンプルサイトへ飛びます。</a>';
    return img;
  };

  static printLogColumn = [
    {
      headerName: Cons.SUMNAIL,
      field: 'sumnale',
      cellRenderer: Util.printLogCellRenderer,
      resizable: true,
      filter: false,
      sortable: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    {
      headerName: Cons.LOGID,
      field: 'logId',
      sortable: true,
      filter: true,
      cellStyle: { font: 'メイリオ', 'font-weight': 'bold' },
      resizable: true,
      // icons: {
      //   sortAscending: '👆',
      //   sortDescending: '👇',
      // },
    },
    {
      headerName: Cons.CLIENTNAME,
      field: 'clientName',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.SERIALNUMBER,
      field: 'serialNumber',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.MODELNAME,
      field: 'modelName',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.MAILADDRESS,
      field: 'mailAddress',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.JOBTYPE,
      field: 'jobType',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.STARTTIME,
      field: 'startTime',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.ENDTIME,
      field: 'endTime',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.JOBSTATUS,
      field: 'jobStatus',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.FILENAME,
      field: 'fileName',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.FILEFORMAT,
      field: 'fileFormat',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.FILEPAGENUM,
      field: 'filePageNum',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.FILEUPLOADTIME,
      field: 'fileUploadTime',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.PAPERSIZE,
      field: 'paperSize',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.PAPERTYPE,
      field: 'paperType',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.COLORMODE,
      field: 'colorMode',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.LAYOUT,
      field: 'layout',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.NUMBEROFCOPIES,
      field: 'numberOfCopies',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.ONEORTWOSIDE,
      field: 'oneOrTwoSide',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.PRINTRANGE,
      field: 'printRange',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.ALLPRINTNUM,
      field: 'allPrintNum',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.ALLPAPERNUM,
      field: 'allPaperNum',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.ACTUALPRINTNUM,
      field: 'actualPrintNum',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.ACTUALPAPERNUM,
      field: 'actualPaperNum',
      sortable: true,
      resizable: true,
    },
  ];

  static userListColumn = [
    {
      headerName: Cons.MAILADDRESS,
      field: 'mailAddress',
      resizable: true,
      filter: true,
      sortable: false,
      // headerCheckboxSelection: true,
      // headerCheckboxSelectionFilteredOnly: true,
      // checkboxSelection: true,
    },
    {
      headerName: Cons.GROUP,
      field: 'group',
      sortable: true,
      filter: true,
      cellStyle: { font: 'メイリオ', 'font-weight': 'bold' },
      resizable: true,
    },
    {
      headerName: Cons.FREEA,
      field: 'columA',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.FREEB,
      field: 'columB',
      sortable: true,
      resizable: true,
    },
    {
      headerName: Cons.FREEC,
      field: 'columC',
      sortable: true,
      resizable: true,
    },
  ];

  static defaultColDef = {
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 250,
    //filter: 'gridFilterComponent',
    resizable: true,
    lockPosition: true,
    headerComponentParams: { menuIcon: 'fa-bars' },
  };
}
