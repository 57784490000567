import { Injectable } from '@angular/core';
import { DialogInfo } from '../model/dialog-info';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  // エラーダイアログ用オブジェクト
  dialogInfo: DialogInfo = new DialogInfo();
  callbackDialog: Function = () => {};

  constructor() {}

  setDialogInfo = (dialogInfo: DialogInfo) => {
    this.dialogInfo = dialogInfo;
  };

  getDialogInfo = () => {
    return this.dialogInfo;
  };
}
