import { AfterViewInit, Component, OnInit } from '@angular/core';

import { TsLogger } from '../appcommon/log/log';
import { LoadingService } from '../appcommon/service/loading.service';
import { Util } from '../appcommon/util/util';
import { WordInfoService } from '../appcommon/service/word-info.service';
import { Router } from '@angular/router';
import * as Cons from '../appcommon/constants/constants';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss'],
})
export class TopComponent implements OnInit, AfterViewInit {
  private configuredFlag: boolean = false;
  private cidWidget: { display: Function; configure: Function } = {
    display: () => {},
    configure: () => {},
  };
  constructor(
    public loadingService: LoadingService,
    public wordInfoService: WordInfoService,
    private router: Router
  ) {
    this.loadingService.setLoading = true;
    window.addEventListener('message', this.receiveMessageFromCid.bind(this));
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.loadCanonIdScript(this.addFrame.bind(this));
  }
  //キヤノンIDjsロード
  loadCanonIdScript(callback: Function) {
    //cssの追加と読込
    const css = document.createElement('link');
    css.rel = 'stylesheet';
    css.href =
      Util.getCanonIdUrl() + 'canonid/canonid-assets/widget-service.min.css';
    css.media = 'screen';
    css.title = 'no title';
    css.charset = 'utf-8';
    const divCss = document.getElementById('css') as HTMLElement;
    divCss.insertAdjacentElement('afterend', css);
    //jsの追加と読込
    const script = document.createElement('script');
    script.src =
      Util.getCanonIdUrl() + 'canonid/canonid-assets/widget-service.min.js';
    const divScript = document.getElementById('script') as HTMLElement;
    divScript.insertAdjacentElement('afterend', script);
    // @ts-ignore
    script.onload = script.onreadystatechange = () => {
      if (
        // @ts-ignore
        !this.readyState ||
        // @ts-ignore
        this.readyState === 'loaded' ||
        // @ts-ignore
        this.readyState === 'complete'
      ) {
        callback();
        // @ts-ignore
        script.onload = script.onreadystatechange = null;
      }
    };
  }

  addFrame() {
    // @ts-ignore
    this.cidWidget = window.cidWidget;
    const iframe = document.createElement('div');
    iframe.className = 'gcid-main-wrapper';
    iframe.id = 'gcid-main-wrapper';
    iframe.innerHTML =
      '<iframe id="cid-iframe" src="' +
      Util.getCanonIdUrl() +
      'canonid/login.html" frameborder="0" scrolling="no"></iframe>';
    const divIframe = document.getElementById('iframe') as HTMLElement;
    divIframe.insertAdjacentElement('afterend', iframe);
  }

  receiveMessageFromCid(msg: { data: { status: string; type: string } }) {
    if (msg.data.type === 'widgetState' && msg.data.status === 'initialized') {
      let widgetConfiguration = {
        clientID: Util.getCanonIdClientId(),
        clientMsg: '',
        messageBot: '',
        redirectURL:
          'https://' + window.location.hostname + '/api/v1/page/session',
        endpoint: Util.getCanonIdUrl() + 'canonid',
        language: 'ja-JP',
        modal: true,
        embedded: true,
        scope: 'openid email',
        returnBasicUserData: true,
      };
      this.cidWidget.configure(widgetConfiguration);
      this.loadingService.setLoading = true;
    }
    if (msg.data.status === 'configured') {
      TsLogger().log('設定完了');
      this.configuredFlag = true;
      this.loadingService.setLoading = false;
      TsLogger().log('widgetConfiguration end');
    }
  }

  onStart() {
    TsLogger().log(this.configuredFlag);
    if (this.configuredFlag == true) {
      this.cidWidget.display();
    } else {
      TsLogger().log('Widget準備中');
    }
  }

  toSoftLicense() {
    this.router.navigate([Cons.SOFTLICENSE]);
  }
}
