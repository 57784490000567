import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Util } from '../util/util';
import { environment } from 'src/environments/environment';
import { AccessorService } from './accessor.service';
import { DialogService } from './dialog.service';
import { AdminCommonService } from './admin-common.service';
import { TsLogger } from '../log/log';

type nonce = { nonce: string };

@Injectable({
  providedIn: 'root',
})
export class XsrfTokenService {
  constructor(
    private accessorService: AccessorService,
    private dialogService: DialogService,
    private adminCommonService: AdminCommonService
  ) {}

  //トークン取得用のURL設定を行う
  getXsrfTokenObservable = (): Observable<nonce> => {
    // とりあえず同じURLを呼ぶものだけでも共通化する。
    let response: Observable<nonce>;
    if (Util.getDevMode()) {
      const url = environment.getXsrfToken;
      response = this.accessorService.get(url);
    } else {
      const url = Util.getXsrfTokenUrl();
      response = this.accessorService.get(url);
    }
    return response;
  };

  //xsrfトークンを新規・再取得処理を実行する
  updateXsrfToken = () => {
    return new Promise((resolve) => {
      let response = this.getXsrfTokenObservable();
      response.subscribe(
        (data) => {
          this.setUpdateXsrfTokenSuccess(data);
          resolve('success');
        },
        (error) => {
          TsLogger().log(error);
          resolve('error');
        }
      );
    });
  };

  setUpdateXsrfTokenSuccess = (data: nonce) => {
    this.adminCommonService.setXsrfToken = data.nonce;
  };
}
