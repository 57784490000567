import { CookieService } from 'ngx-cookie-service';

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminComponent } from './admin/admin.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppcommonModule } from './appcommon/appcommon.module';
import { TabService } from './appcommon/service/tab.service';
import { AdminContainerComponent } from './container/admin-container/admin-container.component';
import { AdminDeleteContainerComponent } from './container/admin-delete-container/admin-delete-container.component';
import { ClientDeleteComponent } from './container/client-delete/client-delete.component';
import { ClientRegisterComponent } from './container/client-register/client-register.component';
import { DialogComponent } from './container/dialog/dialog.component';
import { LogDownloadComponent } from './container/log-download/log-download.component';
import { SelectBoxComponent } from './container/select-box/select-box.component';
import { TabContainerComponent } from './container/tab-container/tab-container.component';
import { UserDeleteComponent } from './container/user-delete/user-delete.component';
import { UserSearchComponent } from './container/user-search/user-search.component';
import { UsersRegisterComponent } from './container/users-register/users-register.component';
import { ErrorComponent } from './error/error.component';
import { HeaderComponent } from './header/header.component';
import { NotFoundUserComponent } from './not-found-user/not-found-user.component';
import { TopComponent } from './top/top.component';
import { PrintLogGridComponent } from './container/print-log-grid/print-log-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { GridHeaderComponent } from './container/grid-header/grid-header.component';
import { GridFilterComponent } from './container/grid-filter/grid-filter.component';
import { SideMenuComponent } from './container/side-menu/side-menu.component';
import { UserListGridComponent } from './container/user-list-grid/user-list-grid.component';
import { UserDialogComponent } from './container/user-dialog/user-dialog.component';
import { UserSettingsHeaderComponent } from './container/user-settings-header/user-settings-header.component';
import { UserSettingsComponent } from './container/user-settings/user-settings.component';
import { DateDialogComponent } from './container/date-dialog/date-dialog.component';
import { PrintlogDateComponent } from './container/printlog-date/printlog-date.component';
import { CalendarComponent } from './container/calendar/calendar.component';
import { DateRangePickerComponent } from './container/date-range-picker/date-range-picker.component';
import { TabAddComponent } from './container/tab-add/tab-add.component';
import { TabDeleteComponent } from './container/tab-delete/tab-delete.component';
import { TenantAdminRegisterComponent } from './container/tenant-admin-register/tenant-admin-register.component';
import { TenantAdminSearchComponent } from './container/tenant-admin-search/tenant-admin-search.component';
import { TenantAdminDeleteComponent } from './container/tenant-admin-delete/tenant-admin-delete.component';
import { VendorRegisterComponent } from './container/vendor-register/vendor-register.component';
import { VendorDeleteComponent } from './container/vendor-delete/vendor-delete.component';
import { VendorAdminRegisterComponent } from './container/vendor-admin-register/vendor-admin-register.component';
import { VendorAdminDeleteComponent } from './container/vendor-admin-delete/vendor-admin-delete.component';
import { VendorAdminSearchComponent } from './container/vendor-admin-search/vendor-admin-search.component';
import { SettingsComponent } from './settings/settings.component';
import { SettingsContainerComponent } from './container/settings-container/settings-container.component';
import { SoftwareLicenseComponent } from './software-license/software-license.component';
@NgModule({
  declarations: [
    AppComponent,
    DialogComponent,
    HeaderComponent,
    SelectBoxComponent,
    ErrorComponent,
    AdminComponent,
    AdminContainerComponent,
    TabContainerComponent,
    AdminDeleteContainerComponent,
    LogDownloadComponent,
    UserSearchComponent,
    UsersRegisterComponent,
    UserDeleteComponent,
    TopComponent,
    NotFoundUserComponent,
    ClientRegisterComponent,
    ClientDeleteComponent,
    PrintLogGridComponent,
    GridHeaderComponent,
    GridFilterComponent,
    SideMenuComponent,
    UserListGridComponent,
    UserDialogComponent,
    UserSettingsHeaderComponent,
    UserSettingsComponent,
    DateDialogComponent,
    PrintlogDateComponent,
    CalendarComponent,
    DateRangePickerComponent,
    TabAddComponent,
    TabDeleteComponent,
    TenantAdminRegisterComponent,
    TenantAdminSearchComponent,
    TenantAdminDeleteComponent,
    VendorRegisterComponent,
    VendorDeleteComponent,
    VendorAdminRegisterComponent,
    VendorAdminDeleteComponent,
    VendorAdminSearchComponent,
    SettingsComponent,
    SettingsContainerComponent,
    SoftwareLicenseComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppcommonModule,
    AppRoutingModule,
    AgGridModule.withComponents([]),
  ],
  entryComponents: [
    AdminContainerComponent,
    AdminDeleteContainerComponent,
    LogDownloadComponent,
  ],
  providers: [CookieService, TabService],
  bootstrap: [AppComponent],
})
export class AppModule {}
