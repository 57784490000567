<div class="logDownloadArea">
    <h2 class='logDownloadArea_name'>{{initializeService.getTenantName}}</h2>
    <!-- ログダウンロード -->
    <button class="logDownloadArea_date" (click)="onDesignatedMonthSelect('')">
        {{logService.getDesignatedMonth}}</button>
    <app-select-box *ngIf="dropBoxService.getDesignatedFlg" (itemValue)="onDesignatedMonthSelect($event)"
        (startFlg)="onDropDownView()" [itemList]="dropBoxService.getDesignatedMonthList" [imageOffFlag]="true">
    </app-select-box>

    <button *ngIf="wordInfoService.getWordInfo()" class="logDownloadArea_output"
        (click)="onDownLoadLog()">{{wordInfoService.getWord('downLoadBtn')}}</button>
</div>