import {animate, animateChild, group, query, sequence, state, style, transition, trigger} from '@angular/animations';



export const sideDisable = trigger('sideDisable', [
  transition('void => *', [
    style({ opacity: 0 }),
    animate('300ms', style({ opacity: 1 })),
  ]),
  transition('* => void', [
    style({ opacity: '1' }),
    animate('300ms', style({ opacity: 0 })),
  ]),
]);

export const sideMenu = trigger('sideMenu', [
  transition('void => *', [
    style({ left: '-320px' }),
    animate('200ms', style({ left: '0px' })),
  ]),
  transition('* => void', [
    style({ left: '0px' }),
    animate('200ms', style({ left: '-320px' })),
  ]),
]);

export const accordion = trigger('accordion', [
  transition('void => *', [
    style({ height: '0', opacity: 0, overflow: 'hidden' }),
    animate('300ms', style({ height: '*', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ height: '*', opacity: '1', overflow: 'hidden' }),
    animate('300ms', style({ height: '0' }))
  ])
]);

export const mainAccordion = trigger('mainAccordion', [
  state('open', style({ height: '*', opacity: '1', overflow: 'hidden' })),
  state('closed', style({ height: '0', opacity: 0, overflow: 'hidden' })),
  transition('closed => open', [
    animate('300ms')
  ]),
  transition('open => closed', [
    animate('300ms')
  ])
]);

export const slideInAnimation = trigger('routeAnimations', [
  transition('Top => Eula, Eula => Password, Password => Main, Eula => Main, \
    Top => Login, Login => Main, Top => Main, Main => Cart, Main => History', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
      }),
    ]),
    query(':enter', [style({ right: '-100%' })]),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('300ms ease-out', style({ right: '100%' }))], { optional: true }),
      query(':enter', [animate('300ms ease-out', style({ right: '0%' }))]),
    ]),
    query(':enter', animateChild()),
  ]),
  transition('Main => Eula, Eula => Top, Cart => Main, History => Main', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ]),
    query(':enter', [style({ left: '-100%' })]),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('300ms ease-out', style({ left: '100%' }))], { optional: true }),
      query(':enter', [animate('300ms ease-out', style({ left: '0%' }))]),
    ]),
    query(':enter', animateChild()),
  ]),
]);

export const topLoad = trigger('topLoad', [
  transition('done => start', [
    sequence([
      query('.logo-image', [style({opacity: 0})], {optional: true }),
      query('.logo-image', [animate('700ms', style({opacity: 1, filter: 'blur(0px)'}))], {optional: true }),
      query('.logo-image', [animate('500ms', style({opacity: 1, filter: 'blur(4px)'}))], {optional: true }),
      query('.logo-image', [animate('500ms', style({opacity: 1, filter: 'blur(0px)'}))], {optional: true }),
      query('.logo-image', [animate('300ms', style({opacity: 1, filter: 'blur(0px)'}))], {optional: true }),
      animate('300ms', style({right: '100%'})),
    ]),
  ])
]);
