import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SideMenuService {
  constructor() {}

  private hambergerFlag: boolean=false;

  set setHambergerFlag(flag:boolean) {
    this.hambergerFlag = flag;
  }
  get getHambergerFlag() {
    return this.hambergerFlag;
  }
}
