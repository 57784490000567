<header class="header">
  <div class="header_inner" *ngIf="firstTime; else elseHeader">
    <h1 class="logo" *ngIf="wordInfoService.getWordInfo()" [innerHtml]="wordInfoService.getWord('appNameStep2')"></h1>
  </div><!-- header_inner-->
  <ng-template #elseHeader>
    <div class="header_inner">
      <h1 class="logo_click" (click)="onLogo()" *ngIf="wordInfoService.getWordInfo()"
        [innerHtml]="wordInfoService.getWord('appNameStep2')"></h1>
    </div><!-- header_inner-->
  </ng-template>
</header><!-- header -->