import { Component, OnInit } from '@angular/core';

import * as Cons from '../appcommon/constants/constants';
import { DialogService } from '../appcommon/service/dialog.service';
import { DropBoxService } from '../appcommon/service/drop-box.service';
import { LoadingService } from '../appcommon/service/loading.service';
import { LogService } from '../appcommon/service/log.service';
import { TabService } from '../appcommon/service/tab.service';
import { WordInfoService } from '../appcommon/service/word-info.service';
import { XsrfTokenService } from '../appcommon/service/xsrf-token.service';
import { InitializeService } from '../appcommon/service/initialize.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
})
export class AdminComponent implements OnInit {
  constructor(
    public wordInfoService: WordInfoService,
    public dialogService: DialogService,
    public loadingService: LoadingService,
    public tabService: TabService,
    public logService: LogService,
    public dropBoxService: DropBoxService,
    public initializeService: InitializeService,
    private xsrfTokenService: XsrfTokenService,
    private router: Router
  ) {
    //loading処理
    this.loadingService.setLoading = true;
    //指定月の設定
    let dateNow = new Date();
    let twoyearsAgo = new Date();
    //3か月前を取得
    twoyearsAgo.setFullYear(twoyearsAgo.getFullYear() - 2);
    let arrayDesignitedMonth: Array<string> = new Array();
    while (twoyearsAgo.getTime() <= dateNow.getTime()) {
      arrayDesignitedMonth.push(this.logService.getMonthData.get(twoyearsAgo.getMonth().toString()) + ' ' + twoyearsAgo.getFullYear().toString());
      twoyearsAgo.setMonth(twoyearsAgo.getMonth() + 1);
    }

    console.log(this.logService.getMonthData.get(twoyearsAgo.getMonth().toString()) + ' ' + twoyearsAgo.getFullYear().toString());
    twoyearsAgo.setMonth(twoyearsAgo.getMonth() - 1);
    this.logService.setDesignatedMonth = this.logService.getMonthData.get(twoyearsAgo.getMonth().toString())+ ' ' + twoyearsAgo.getFullYear().toString();
    this.dropBoxService.setDesignatedMonthList = arrayDesignitedMonth;

    //クライアント名等の初期処理の取得等を行う。
    (async () => {
      await this.xsrfTokenService.updateXsrfToken();
      const result = await this.initializeService.setTenantName();
      if (result == 'error') {
        this.router.navigate(['/' + Cons.TOP]);
      }
      //セッションが切れた時に一瞬メインページに進んでしまうため項目を見せないようにする
      this.initializeService.setInitializeCompleteFlg = true;
      this.loadingService.setLoading = false;
    })();
  }
  ngOnInit(): void {}
}
