import { Component, OnInit, Input } from '@angular/core';
import { WordInfoService } from '../../appcommon/service/word-info.service';
import { Router } from '@angular/router';
import { LogOffService } from '../../appcommon/service/log-off.service';
import * as Cons from '../../appcommon/constants/constants';
import { sideDisable, sideMenu } from '../../animation';
import { SideMenuService } from '../../appcommon/service/side-menu.service';
import { TsLogger } from 'src/app/appcommon/log/log';
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  animations: [sideDisable, sideMenu],
})
export class SideMenuComponent implements OnInit {
  @Input() firstTime: boolean = false;
  @Input() nowPrint: boolean = false;
  @Input() nowJobhistory: boolean = false;
  @Input() nowSetting: boolean = false;
  //プリントジョブの失敗を知らせるための変数

  constructor(
    private router: Router,
    public wordInfoService: WordInfoService,
    public logOffService: LogOffService,
    public sideMenuService: SideMenuService
  ) {}

  ngOnInit(): void {
    this.sideMenuService.setHambergerFlag = false;
  }

  onMain() {
    this.transition_pages(Cons.ADMIN);
    TsLogger().log(Cons.ADMIN);
    this.onHambergerMenu(false);
  }

  onHistory() {
    //this.transition_pages(Cons.JOBHISTORY);
    TsLogger().log(Cons.JOBHISTORY);
    this.onHambergerMenu(false);
  }

  onSetting() {
    this.transition_pages(Cons.SETTINGS);
    TsLogger().log(Cons.SETTINGS);
    this.onHambergerMenu(false);
  }

  onHambergerMenu(flag: boolean) {
    this.sideMenuService.setHambergerFlag = flag;
  }

  onLogout() {
    this.logOffService.deleteSession();
  }

  // 移動
  transition_pages(nextPage: string) {
    this.router.navigate(['/' + nextPage]);
  }
}
