import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';

import { HttpErrorResponse } from '../../../../node_modules/@angular/common/http';
import * as Cons from '../../appcommon/constants/constants';
import { TsLogger } from '../../appcommon/log/log';
import { DialogService } from '../../appcommon/service/dialog.service';
import { DropBoxService } from '../../appcommon/service/drop-box.service';
import { InitializeService } from '../../appcommon/service/initialize.service';
import { LoadingService } from '../../appcommon/service/loading.service';
import { LogService } from '../../appcommon/service/log.service';
import { WordInfoService } from '../../appcommon/service/word-info.service';
import { Util } from '../../appcommon/util/util';

@Component({
  selector: 'app-log-download',
  templateUrl: './log-download.component.html',
  styleUrls: ['./log-download.component.scss'],
})
export class LogDownloadComponent implements OnInit {
  //ファイル作成完了メッセージ
  createFileCopleteMsg: string = '';
  constructor(
    public wordInfoService: WordInfoService,
    public dialogService: DialogService,
    public logService: LogService,
    public dropBoxService: DropBoxService,
    public initializeService: InitializeService,
    private loadingService: LoadingService,

  ) {
    async () => {
      await this.initializeService.setTenantName();
    }
  }

  ngOnInit(): void {}

  onDropDownView = () => {
    this.dropBoxService.setScrollHeight(this.logService.getDesignatedMonth);
  };

  //ログをダウンロードする。
  onDownLoadLog = () => {
    this.loadingService.setLoading = true;
    if (environment.timerOn) {
      performance.mark('start');
    }
    //ログファイルの作成をキックする
    this.postLogFileCreate();
  };

  postLogFileCreate = () => {
    let response = this.logService.postLogfileCreateService();
    response.subscribe(
      // 通信成功時
      (data) => {
        //sessionのチェック
        let dialogInfo = Util.getSessionErrorDialog(data);
        if (dialogInfo.body) {
          this.initializeService.setInitializeCompleteFlg = false;
          this.dialogService.setDialogInfo = dialogInfo;
          return;
        }
        TsLogger().log(data);
        TsLogger().log('通信成功');
        this.setDownloadFileFunc(data['printLogDownloadId']);
      },
      (error) => {
        this.setErrorFunc(error);
      }
    );
  };

  setDownloadFileFunc = (logDownloadId: string) => {
    let response = this.logService.getLogInfo(logDownloadId);
    response.subscribe(
      // 通信成功時
      (data) => {
        this.setDownloadFileFuncSuccess(data, logDownloadId);
      },
      (error) => {
        this.setErrorFunc(error);
      }
    );
  };
  //URLをゲット成功後の設定を行う
  setDownloadFileFuncSuccess = (
    data: {
      printLogDownloadStatus: string;
      url: string;
    },
    logDownloadId: string
  ) => {
    //sessionのチェック
    let dialogInfo = Util.getSessionErrorDialog(data);
    if (dialogInfo.body) {
      this.initializeService.setInitializeCompleteFlg = false;
      this.dialogService.setDialogInfo = dialogInfo;
      return;
    }
    TsLogger().log(data);
    TsLogger().log('通信成功');
    //statusコードチェック
    if (
      'printLogDownloadStatus' in data &&
      data['printLogDownloadStatus'] === Cons.CREATE_LOG_CONVERTED
    ) {
      if (environment.timerOn) {
        performance.mark('end');
        performance.measure('logDownLoad', 'start', 'end');
        const results = performance.getEntriesByName('logDownLoad');
        TsLogger().log(results[0]);
      }
      let url = data['url'];
      this.loadingService.setLoading = false;
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.download =
        'ログファイル_' + this.logService.getDesignatedMonth + '.zip';
      a.href = url;
      a.click();
    } else {
      setTimeout(() => this.setDownloadFileFunc(logDownloadId), 2000);
    }
  };

  setErrorFunc = (error: HttpErrorResponse) => {
    TsLogger().log('通信失敗');
    this.loadingService.setLoading = false;
    if (error.status == 0) {
      this.dialogService.setDialogInfo = {
        body: 'errUploadTimeout',
        footer: false,
        next: '',
      };
      return;
    }
    this.dialogService.setDialogInfo = Util.getDialogInfoError(error);
  };

  // selectboxの表示・非表示スイッチ
  onDesignatedMonthSelect = (value: string): void => {
    this.dropBoxService.setDesignatedFlg = this.dropBoxService.getDesignatedFlg
      ? false
      : true;
    if (value) {
      this.logService.setDesignatedMonth = value;
    }
  };
}
